import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  AddPrice,
  createProduct,
  getCategory,
  getMeasurements,
} from '../productprice-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../productprice-list/core/QueryResponseProvider'
import {getProductDeatils} from '../../profile/profile-list/core/_requests'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

//Model Interface
export interface IProfileDetails {
  name: string
  category_id: string
  product_price: [
    {
      market: {
        name: string
      }
      measurement: string
      price: string
    }
  ]
  createdBy: string | undefined
}
interface MarketProductItem {
  market_id: any
  product_id: any
  units: string
  measurement: string
  price: string
}
//Model Initial Value
export let initialValues: IProfileDetails = {
  name: '',
  category_id: '',
  product_price: [
    {
      market: {
        name: '',
      },
      measurement: '',
      price: '',
    },
  ],

  createdBy: '',
}

//Validation
const roleDetailsSchema = Yup.object().shape({})

//Function Starts
const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)

  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const types = currentUser?.type
  const [loading, setLoading] = useState(false)
  const [marketProductList, setmarketProductList] = useState<any>([
    // Initial items
  ])
  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)

      var newData = productList.map((item: any) => {
        return {
          ...item,
          userid: currentUser?.userId,
          category_id: categoryID,
        }
      })

      resetForm({
        ...initialValues,
        values: {...initialValues},
      })
      
      AddPrice(newData).then((res) => {
        showAlert(res?.data?.Message, res?.data?.Code)
        navigate('/price/add', {replace: true})
        setcategoryID('')
      })
      //

      setLoading(false)
    },
    enableReinitialize: true,
  })

  // Get role using type
  const [category, setcategory] = useState<any>([])
  const [unit, setunit] = useState<any>([])
  const [productList, setproductList] = useState<any>([])
  const [categoryID, setcategoryID] = useState('')
  useEffect(() => {
    getCategory().then((res: any) => {
      setcategory(res.data.data)
      
    })
  }, [])
  useEffect(() => {
    getMeasurements().then((res: any) => {
      setunit(res.data.Data)
      
    })
  }, [])
  //
  const handleCategory = (e: any) => {
    formik.setFieldValue('category_id', e.target.value)
    setcategoryID(e.target.value)
    const selectedOption = e.target.selectedOptions[0]
    const dataId = selectedOption.getAttribute('data-id')
    formik.setFieldValue('cat_mas_id', dataId)
    getProductDeatils(e.target.value).then((res: any) => {
      // 
      setproductList(res.data.Data)
    })
    // 
  }

  function formatDate(dateString: any) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
  }

  const handleOnChange = (e: any, field: any, marketId: any, productId: any) => {
    // 
    const updatedProductList = productList.map((product: any) => {
      const updatedMarketProducts = product.market_products.map((item: any) => {
        if (item.market_id === marketId && item.product_id === productId) {
          // Update the specific field based on the 'field' parameter
          return {
            ...item,
            [field]: e.target.value,
          }
        }
        return item
      })

      return {
        ...product,
        market_products: updatedMarketProducts,
      }

      return product
    })

    // Update the state with the modified productList
    setproductList(updatedProductList)
  }
  const {show, message, type} = useContext(AlertContext)
  // 
  return (
    <div className='card mb-5 mb-xl-10'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Product Price</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Category</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('category_id')}
                    onChange={(e) => handleCategory(e)}
                  >
                    <option value=''>Select a Category</option>
                    {category.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id} data-id={item?.cat_mas_id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.category_id && formik.errors.category_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.category_id}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {categoryID && (
            <>
              <div className='card-body border-top p-9'>
                {productList.map((item: any, i: any) => {
                  return (
                    <div className='row mb-6'>
                      <h3 className='mb-5' style={{color: '#f5a14d'}}>
                        {item?.name}
                      </h3>
                      {item?.market_products.map((items: any, j: any) => {
                        const originalDate = items?.prevPriceInfo?.createdAt
                        const formattedDate = formatDate(originalDate)
                         // Output: "02-07-2020 12:48:14"

                        return (
                          <>
                            {j === 0 && (
                              <div className='row mb-5'>
                                <div className='col-lg-1  fv-row fs-4'>S.NO</div>
                                <div className='col-lg-3  fv-row fs-4'>Market</div>
                                <div className='col-lg-2  fv-row fs-4'>Quantity</div>
                                <div className='col-lg-2 fv-row fs-4'>Measurement</div>
                                <div className='col-lg-2  fv-row fs-4'>Price</div>
                                <div className='col-lg-2  fv-row fs-4'>Old Price</div>
                              </div>
                            )}

                            <div className='row mb-5'>
                              <div className='col-lg-1 fv-row'>{j + 1}</div>
                              <div className='col-lg-3 fv-row'>{items?.market?.name}</div>
                              <div className='col-lg-2 fv-row'>
                                <input
                                  type='text'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder='Qty'
                                  // {...formik.getFieldProps('qty')}
                                  value={items?.units}
                                  onChange={(e) => {
                                    handleOnChange(e, 'units', items?.market_id, items?.product_id)
                                  }}
                                />

                                {!items?.units && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Qty is required</div>
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-2 fv-row'>
                                <select
                                  className='form-control form-control-lg form-control-solid'
                                  // {...formik.getFieldProps('items?.measurement')}
                                  value={items?.measurement}
                                  onChange={(e) => {
                                    handleOnChange(
                                      e,
                                      'measurement',
                                      items?.market_id,
                                      items?.product_id
                                    )
                                  }}
                                >
                                  <option value=''>Select a Unit</option>
                                  {unit.map((itemU: any, i: any) => {
                                    return (
                                      <option key={i} value={itemU.id}>
                                        {itemU.name}
                                      </option>
                                    )
                                  })}
                                </select>

                                {!items?.measurement && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Unit is required</div>
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-2 fv-row'>
                                <input
                                  type='text'
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                  placeholder='Price'
                                  // {...formik.getFieldProps('price')}
                                  value={items?.price}
                                  onChange={(e) => {
                                    handleOnChange(e, 'price', items?.market_id, items?.product_id)
                                  }}
                                />
                                {!items?.price && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Price is required</div>
                                  </div>
                                )}
                              </div>
                              {items?.prevPriceInfo && (
                                <div className='col-lg-2 fv-row' style={{color: '#88b377'}}>
                                  {items?.prevPriceInfo?.price} /{items?.prevPriceInfo?.units}{' '}
                                  {items?.prevPriceInfo?.price_measurement?.name}
                                  <br></br>
                                  {formattedDate}
                                </div>
                              )}
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )
                })}
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  )
}

export default RoleDetails
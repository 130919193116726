import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createMarket, duplicateData, getCity, getCountry, getState} from '../market-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../market-list/core/QueryResponseProvider'

//Model Interface
export interface IProfileDetails {
  name: string
  country: string
  state: string
  city: string
  location: string

  createdBy: string | undefined
  Code: string
  Message: string
}

//Model Initial Value
export let initialValues: IProfileDetails = {
  name: '',
  country: '',
  city: '',
  state: '',
  location: '',
  createdBy: '',
  Code: '',
  Message: '',
}

//Validation
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Market name is required').matches(/^[a-zA-Z\s]*$/, 'Only letters are allowed'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  location: Yup.string().required('Location is required'),
})

//Function Starts
const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const type = currentUser?.type
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      

      createMarket(updatedData).then((res) => {
        
        initialValues = {
          name: '',
          country: '',
          city: '',
          state: '',
          location: '',
          createdBy: '',
          Code: '',
          Message: '',
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/market', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.data?.Message, res?.data?.Code)
      })
    },
    enableReinitialize: true,
  })

  // Get role using type
  const [country, setCountry] = useState<any>([])
  const [city, setCity] = useState<any>([])
  const [state, setState] = useState<any>([])
  useEffect(() => {
    getCountry().then((res: any) => {
      setCountry(res.data.data)
    })
  }, [])
  const handleChange = (e: any) => {
    
    formik.setFieldValue('country', e.target.value)
    getState(e.target.value).then((res: any) => {
      
      setState(res.data.data)
    })
  }
  //
  const handleStateChange = (e: any) => {
    
    formik.setFieldValue('state', e.target.value)
    getCity(e.target.value).then((res: any) => {
      setCity(res.data.data)
    })
  }
  const handleCancel = () => {
    navigate(-1)
  }

  const [Duplicate, setDuplicate] = useState<any>('')

  // Duplicate check function
  const DuplicateCheck = async (val: string) => {
    try {
      const value = {
        name: val,
      }
      if (val.length >= 3) {
        const response = await duplicateData(value)
        console.log('response?.isDuplicate', response?.isDuplicate)
        if (response?.isDuplicate === true) {
          setDuplicate('Market already exists')
        } else {
          setDuplicate('')
        }
      }
    } catch (error) {
      console.error('Error checking for duplicate user type:', error)
    }
  }

  // Integrating both Formik's handleChange and DuplicateCheck in one function
  const handleNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    formik.setFieldValue('name', value) // Updates Formik's field value
    await DuplicateCheck(value) // Performs the duplicate check
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Market </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Market Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    {...formik.getFieldProps('name')}
                    onChange={handleNameChange} // Combined handleChange and DuplicateCheck
                    />
                       {Duplicate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{Duplicate}</div>
                      </div>
                    )}
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Country</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('country')}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value=''>Select a Country</option>
                    {country.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.country}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>State</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('state')}
                    onChange={(e) => handleStateChange(e)}
                  >
                    <option value=''>Select a State</option>
                    {state.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.state && formik.errors.state && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.state}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>City</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('city')}
                  >
                    <option value=''>Select a City</option>
                    {city.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.city}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Location</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Market Location'
                    {...formik.getFieldProps('location')}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.location}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='button' className='btn btn-secondary ms-2' onClick={handleCancel}>
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading || Duplicate}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

import {ID, Response} from '../../../../../../_metronic/helpers'
export type Product = {
  id?: ID
  primaryId?: number
  name?: string
  product: {
    productName?: string
  }
  category_id?: string
  image?: string
  profileNew?: any
  fileName?: any
  Message?: string
  Code?: string
  created_by?: string | undefined
  created_date: string
}
export type FilterState = {
  filter?: unknown
}
export type ProdcutQueryResponse = Response<Array<Product>>

export const initialUser: Product = {
  category_id: '',
  name: '',
  product: {
    productName: '',
  },
  created_date: '',
  image: '',
}

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewNews} from '../news-list/core/_requests'
import {EditEmployee} from './Edit'

export const EditWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewNews(id).then((res) => {
      
      setData(res.Data)
    })
  }, [])
  if (data) {
    return <EditEmployee initialValues={data} />
  }
  return null
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {getSettings, updateSettings} from '../list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

export function ViewBuySellValidity() {
  const {showAlert} = useContext(AlertContext)
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [showModal, setShow] = useState(false)
  const [BkeyVal, setBkeyVal] = useState('')
  const [SkeyVal, setSkeyVal] = useState('')
  const [SkeyValCount, setSkeyValCount] = useState('')
  const [BkeyValCount, setBkeyValCount] = useState('')
  const handleClose = () => setShow(false)
  useEffect(() => {
    getInitialValues()
  }, [showModal])
  const getInitialValues = () => {
    getSettings().then((res: any) => {
      setData(res.data?.data[0])
      
      setBkeyVal(res.data?.data[0]?.buyer_product_validity)
      setSkeyVal(res.data?.data[0]?.seller_product_validity)
      setBkeyValCount(res.data?.data[0]?.buyer_request_perday)
      setSkeyValCount(res.data?.data[0]?.seller_request_perday)
    })
  }
  const handleEdit = () => {
    
    setShow(true)
  }
  const SubmitHandle = () => {
    const datas = {
      buyer_validity: BkeyVal,
      seller_validity: SkeyVal,
      seller_count: SkeyValCount,
      buyer_count: BkeyValCount,
      id: data?.id,
    }

    updateSettings(datas).then((res) => {
      
      getInitialValues()
      showAlert(res?.data?.Message, res?.data?.Code)
      navigate('/settings/buySellValidity', {replace: true})
    })

    
    setShow(false)
  }
  const handleInputKey = (e: any) => {
    setBkeyVal(e.target.value)
  }
  const handleInputSKey = (e: any) => {
    setSkeyVal(e.target.value)
  }
  const handleInputBKeyCount = (e: any) => {
    setBkeyValCount(e.target.value)
  }
  const handleInputSKeyCount = (e: any) => {
    setSkeyValCount(e.target.value)
  }
  const editrazar = 'settings/edit/' + data?.id

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Details</h3>
              </div>
              <button onClick={handleEdit} className='btn btn-primary align-self-center'>
                Edit
              </button>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Buyer product validity</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.buyer_product_validity} Days
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Seller product validity</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.seller_product_validity} Days
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Buyer Request Per Day</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.buyer_request_perday} Per day
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Seller Request Per Day</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.seller_request_perday} Per day
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Buyer product validity</label>

            <div className='col-lg-8'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter buyer product validity in days'
                value={BkeyVal}
                onChange={(e) => {
                  handleInputKey(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Seller product validity</label>

            <div className='col-lg-8'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter seller product validity in days'
                value={SkeyVal}
                onChange={(e) => {
                  handleInputSKey(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Buyer Request Per Day</label>

            <div className='col-lg-8'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter buyer request per day'
                value={BkeyValCount}
                onChange={(e) => {
                  handleInputBKeyCount(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Seller Request Per Day</label>

            <div className='col-lg-8'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter sellerrequest per day'
                value={SkeyValCount}
                onChange={(e) => {
                  handleInputSKeyCount(e)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={SubmitHandle}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

// import {View} from './user-form/View'
import {UserHeader} from './form/UserHeader'
import {ViewUser} from './form/ViewUser'
import {ViewSMS} from './form/ViewSMS'
import {ViewBuySellValidity} from './form/ViewBuySellValidity'
import {ViewAppSettings} from './form/ViewAppSettings'
import {ViewAppVersion} from './form/ViewAppVersion'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Settings Management',
    path: '/settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/razarpay'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Settings Overview</PageTitle>
              {/* <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>  */}
              <UserHeader />
              <ViewUser />
            </>
          }
        />

        <Route
          path='/smsGateway'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Settings Overview</PageTitle>
              {/* <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>  */}
              <UserHeader />
              <ViewSMS />
            </>
          }
        />
        <Route
          path='/buySellValidity'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Settings Overview</PageTitle>
              {/* <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>  */}
              <UserHeader />
              <ViewBuySellValidity />
            </>
          }
        />
        <Route
          path='/appSettings'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Settings Overview</PageTitle>
              {/* <button onClick={goBack} style={{marginBottom:"15px"}}  className='btn btn-sm btn-info px-6 align-self-center text-nowrap'> 
           <i className="bi bi-reply fs-4 me-2"></i> Go Back </button>  */}
              <UserHeader />
              <ViewAppSettings />
            </>
          }
        />
        <Route
          path='/appVersion'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Settings Overview</PageTitle>

              <UserHeader />
              <ViewAppVersion />
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='razarpay' />} />
    </Routes>
  )
}

export default SettingsPage

import React, {useContext, useEffect, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createUnit} from '../unit-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../unit-list/core/QueryResponseProvider'
import {getExCRUD} from '../../role-access/role-setting-list/core/_requests'

export interface IProfileDetails {
  name: string
  createdBy: string | undefined
}

export let initialValues: IProfileDetails = {
  name: '',
  createdBy: '',
}
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string()
  .matches(/^[A-Za-z\s]+$/, 'Unit Name should only contain alphabetic characters')
  .required('Unit is required'),
})

const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const {currentUser} = useAuth()
  
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      
      createUnit(updatedData).then((res) => {
        initialValues = {
          name: '',
          createdBy: '',
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/unit/list', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    },
    enableReinitialize: true,
  })
  // 

  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 5).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Unit </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Unit Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Unit Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/** Description Info */}
            {/* <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Category Description
                  </label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Category Description'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {roleaccess?.add === 1 && (
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

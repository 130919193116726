import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {News, NewsQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

 

//AuthVerify
var AuthVerify = getToken.call(this)

// LIST
const getNews = (query: string): Promise<NewsQueryResponse> => {
  return axios
    .get(`${API_URL}/news/list/query?${query}`, getToken())
    .then((response: AxiosResponse<NewsQueryResponse>) => {
      
      return response.data
    })
}

// ADD
const createNews = (data: FormData): Promise<News | undefined> => {
   
  return axios.post(`${API_URL}/news/addNews`, data, getToken()).then((response: any) =>{
    
    return response.data
  })

  
}

// VEIW
const viewNews = (id: any) => {
  return axios.get(`${API_URL}/news/viewNews/${id}`, getToken()).then((response) => {
    return response.data
  })
}

// UPDATE
const updateNews = (data: FormData, id: any) => {
  // 
  return axios.put(`${API_URL}/news/editNews/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}

// DELETE
const deleteById = (id: any) => {
  return axios.put(`${API_URL}/news/deleteNews/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

//GET Category
const getCategory = async () => {
  const res = await axios.get(`${API_URL}/news/NewsCategory`, getToken())
  
  return res
}

export {getNews, createNews, viewNews, updateNews, deleteById, getCategory}

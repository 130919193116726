/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import {viewData} from '../plan-list/core/_requests'
export function View() {
  const {id} = useParams()

  const [plan, setplan] = useState<any>([])

  useEffect(() => {
    viewData(id).then((res: any) => {
    console.log("res.Data",res.Data);
    
      
      setplan(res.Data)
    })
  }, [])
  
  var editsubsob = '/plan/edit/' + id

  //remove client

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Plan Details</h3>
          </div>
          <Link to={editsubsob} className='btn btn-primary align-self-center'>
            Edit Plan
          </Link>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {plan?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan Type</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {plan?.payment_type === 1 ? 'Free Plan' : 'Payment Plan'}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Days</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {plan?.days} Days</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Amount</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {plan?.amount}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan Category</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {plan?.planType?.name}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Description</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {plan?.description} </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Is Popular</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {' '}
                {plan?.is_popular === 0 ? 'Not Popular' : 'Popular'}{' '}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Buyer Post Count / Day</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {' '}
                {plan?.buyerCount ? plan?.buyerCount : '-'}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Seller Post Count / Day</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {' '}
                {plan?.sellerCount ? plan?.sellerCount : '-'}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  getCategory,
  updatePrice,
  updateProduct,
  viewPrice,
} from '../productprice-list/core/_requests'
import {Product, initialUser as initialValues} from '../productprice-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS

export const EditProduct = () => {
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewPrice(id).then((res) => {
      setData(res?.data?.Data)
      setPrice(res?.data?.Data?.price || '')
    })
  }, [])
  const units = data?.units || 0
  const measurementName = data?.price_measurement?.measurementName || 1
  const divisionResult = units + ' / ' + measurementName
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState('')
  
  //get type
  const {currentUser} = useAuth()
  //

  const type = currentUser?.type
  //end get type
  const formik = useFormik<Product>({
    initialValues,

    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        
        updatePrice(id, price).then((res) => {
          showAlert(res?.data?.Message, res?.data?.Code)
          navigate('/price', {replace: true})
        })
        setLoading(false)
      }, 1000)
    },
  })
  const handlePriceChange = (event: any) => {
    const inputPrice = event.target.value

    setPrice(inputPrice)
  }
  const handleCancel = () => {
    // Navigate to the previous page
    navigate(-1)
  }

  
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Edit Product Price </h3>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card mb-5 mb-xl-10 p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Product</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    readOnly
                    value={data?.product?.productName}
                  />
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Market</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    readOnly
                    value={data?.market?.marketName}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Unit</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    readOnly
                    value={divisionResult}
                  />
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Price</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Price'
                    value={price}
                    onChange={handlePriceChange}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' className='btn btn-secondary ms-2' onClick={handleCancel}>
                Cancel
              </button>
              &nbsp;&nbsp;&nbsp;
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditProduct

import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'

import {ListWrapper} from './list/EmployeeList'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { View } from './form/View'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Subscription Management',
    path: '/subscription',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SubscriptionsPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Subscription list</PageTitle>
              <ListWrapper />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/view/:id'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Subscription Details</PageTitle>
              <button
                onClick={goBack}
                style={{marginBottom: '15px'}}
                className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
              >
                <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
              </button>
              <View />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default SubscriptionsPage

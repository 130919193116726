import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../../_metronic/helpers'
import {Plan, QueryResponse} from './_models'
import {getToken} from '../../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

 

//AuthVerify
var AuthVerify = getToken.call(this)

const getData = (query: string): Promise<QueryResponse> => {
  return axios
    .get(`${API_URL}/listUsers/query?${query}`, getToken())
    .then((response: AxiosResponse<QueryResponse>) => {
      return response.data
    })
}
 
// VEIW
const viewData = (userId: any) => {
  return axios.get(`${API_URL}/viewUser/${userId}`, getToken()).then((response) => {
    return response.data
  })
}
const createData = (data: any) => {
  
  return axios.post(`${API_URL}/user/activate-plan`, data, getToken()).then((response) => {
    return response.data
  })
}

// UPDATE
const updateData = (data: any, id: any) => {
  
  return axios.put(`${API_URL}/plan/edit/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}

 

//GET Category
const getPlan = () => {
  return  axios.get(`${API_URL}/plan/getPlan`, getToken()).then((response) => {
    
    return response.data
  })
 
}

export { createData,getData,viewData, updateData, getPlan}

import {Route, Routes, Outlet, Navigate, Link} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ReportWrapper} from './list/ReportWrapper'

import {KTCard} from '../../../../_metronic/helpers'
 
const creportBreadcrumbs: Array<PageLink> = [   
  {
    title: 'Report Management',
    path: '/report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
] 
const ReportPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>  
              <PageTitle  breadcrumbs={creportBreadcrumbs} > Report </PageTitle>
              <ReportWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default ReportPage

import {useListView} from '../../core/ListViewProvider'
import {UserTypeListSearchComponent} from './UserTypeListSearchComponent'
import { UserTypeListToolbar } from './UserTypeListToolbar'

const CategoryListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <UserTypeListSearchComponent />
       {/* begin::Card toolbar */}
       <div className='card-toolbar'>
        {/* begin::Group actions */}
         <UserTypeListToolbar /> 
        {/* end::Group actions */}
      </div>
    </div>
  )
}

export {CategoryListHeader}

import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper, ViewListWrapper} from './market-list/EmployeeList'
import {AddMarket} from './market-form'
import {EditMarketWrapper} from './market-form/EditMarketWrapper'
import {View} from './market-form/View'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Market Management',
    path: '/market',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MarketPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Market list</PageTitle>
              <EmployeeListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Market Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddMarket />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Market Edit</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <EditMarketWrapper />
          </>
        }
      />
      <Route
        path='/view/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Market Edit</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <ViewListWrapper />
          </>
        }
      />
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default MarketPage

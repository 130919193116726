import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Category, CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
 

const getData = (query: string): Promise<CategoryQueryResponse> => {
  return axios.get(`${API_URL}/bsCategory/query?${query}`).then((d: AxiosResponse<CategoryQueryResponse>) => {
    
    return d.data
  })
}
 

const deleteById = (id: any) => {
  return axios
    .put(`${API_URL}/bsCategory/delete/${id}`, getToken())

    .then((response: any) => response.data)
}

const createData = (data:any) => {
  return axios
    .post(`${API_URL}/bsCategory/add`, data, getToken())
    .then((response: any) => response.data)
}

const updateData = (name: any, id: any) => {
  return axios
    .put(`${API_URL}/bsCategory/edit/${id}`, {name}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}

 

 
 
export {
  getData,
  
  createData,
 
  deleteById,
  updateData,
  
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {UserType} from '../../core/_models'

type Props = {
  userType: any
}

const UserTypeInfoCell: FC<Props> = ({userType}) => (
  <div className='d-flex align-items-center'>
    <>{console.log("userType", userType , userType?.icon)}</>
  {/* begin:: Avatar */}
  <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
    <a href='#'>
      <div className='symbol-label'>
        <img src={userType?.icon} className='w-100' />
      </div>
    </a>
  </div>
</div>
)
export {UserTypeInfoCell}

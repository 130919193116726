/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import '../../../user.css'
import {dateFormat} from '../../../../../auth/core/AuthHelpers'

type Props = {
  user: any
}

const EmployeeInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <h6 className={`status-${user?.status}`}>{user?.status}</h6>
  </div>
)

const EmployeePlanStartCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_start ? dateFormat(user?.plan_start) : ''}
  </div>
)
const EmployeePlanEndCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_end ? dateFormat(user?.plan_end) : ''}
  </div>
)
const EmployeePlanRegCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>{dateFormat(user?.created)}</div>
)
const EmployeePlanCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {user?.plan_type !== 0 ? user?.plan?.name : 'No plan '}
  </div>
)

export {
  EmployeeInfoCell,
  EmployeePlanStartCell,
  EmployeePlanEndCell,
  EmployeePlanRegCell,
  EmployeePlanCell,
}

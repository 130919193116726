/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Product} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import { enableCategory } from '../../core/_requests'
import { useState
 } from 'react'
type Props = {
  product: Product,
  columnAccessor: string;
}

const ProductInfoCell: FC<Props> = ({product, columnAccessor}) => {
  const [enableValue, setEnableValue] = useState<number>(parseInt(product.enable_status, 10));

  const handleStatus = () => {
    const updatedStatus = enableValue === 1 ? 0 : 1; 
    setEnableValue(updatedStatus); 
    const data = {
      enable_status: updatedStatus.toString(),  
    };
    const id = product.primaryId;
    enableCategory(data, id)
      .then((res: any) => {
        
      })
      .catch((error: any) => {
        console.error('Error updating category status:', error);
      });
  };

  return (
    <>
    {columnAccessor === 'image' && (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          <div className='symbol-label'>
            <img src={product.image} className='w-100' />
          </div>
        </a>
      </div>
    </div>
    )}
     {columnAccessor === 'status' && (
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            defaultValue='1'
            checked={enableValue === 1}
            onChange={handleStatus}
          />
          <label className='form-check-label' htmlFor='enableSwitch'>
            {/* {enableValue === 1 ? 'Enabled' : 'Disabled'} */}
          </label>
        </div>
      )}
    </>
  )
}

export {ProductInfoCell}

import React, { FC, useContext, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCategory, getUserType, updateData } from '../plan-list/core/_requests'
import { Plan } from '../plan-list/core/_models'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../auth'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'

const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  amount: Yup.string().required('Amount is required'),
  payment_type: Yup.string().required('Payment type is required'),
  days: Yup.string().required('Days is required'),
  description: Yup.string().required('Give some short description'),
  planType: Yup.string().required('User type is required'),
  buyerCount: Yup.string().required('Buyer count is required'),
  sellerCount: Yup.string().required('Seller count is required'),
})

type Props = {
  initialValues: Plan
}

export const EditEmployee: FC<Props> = ({ initialValues }) => {
  // Transform initialValues to extract planType.id
  const transformedInitialValues = {
    ...initialValues,
    planType: initialValues.planType.id
  }
  
  const [data, setData] = useState<Plan>(initialValues)
  const { showAlert } = useContext(AlertContext)
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)

  // Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  // Get type
  const { currentUser } = useAuth()
  // End get type
  
  const formik = useFormik<Plan>({
    initialValues: transformedInitialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = { ...data, ...values }
        updatedData.employee_id = currentUser?.userId

        updateData(updatedData, id).then((res) => {
          showAlert(res?.Message, res?.Code)
          navigate('/plan', { replace: true })
        })
        setLoading(false)
      }, 1000)
    },
  })

  const [planType, setPlanType] = useState<any>([])
  
  useEffect(() => {
    getUserType().then((res: any) => {
      setPlanType(res?.data)
    })
  }, [])
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Plan</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Payment Type</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('payment_type')}
                  >
                    <option value=''>Select a Type</option>
                    <option value='1'>Free Plan</option>
                    <option value='0'>Payment Plan</option>
                  </select>
                  {formik.touched.payment_type && formik.errors.payment_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.payment_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan Category</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('planType')}
                  >
                    <option value=''>Select a Type</option>
                    {planType.map((item: any, i: any) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.planType && formik.errors.planType && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{String(formik.errors.planType)}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Days</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('days')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.days && formik.errors.days && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.days}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Amount</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Amount'
                    {...formik.getFieldProps('amount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Description</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Description'
                    {...formik.getFieldProps('description')}
                  ></textarea>
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Buyer Post Count / Day
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter buyer post count / day'
                    {...formik.getFieldProps('buyerCount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.buyerCount && formik.errors.buyerCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.buyerCount}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Seller Post Count / Day
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter seller post count / day'
                    {...formik.getFieldProps('sellerCount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.sellerCount && formik.errors.sellerCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.sellerCount}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditEmployee

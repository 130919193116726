/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteCategoryById} from '../../core/_requests'
import React from 'react'
import {MyContext} from '../../ContextFile'
import {Category} from '../../core/_models'
import {Button, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  cate: Category
}

const CategoryActionsCell: FC<Props> = ({cate}) => {
  // 
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showModal, setShow] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const {state, setState} = React.useContext(MyContext)

  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const handleClose = () => {
    setShow(false)
  }
  const deleteItem = () =>
    deleteCategoryById(cate?.primaryId).then((res) => {
      setShow(false)
      navigate('/newsCategory/list', {replace: true})
      showAlert(res?.Message, res?.Code)
      refetch()
    })
  const {currentUser} = useAuth()
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 5).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {roleaccess?.edit === 1 && (
          <a
            href='#'
            onClick={() => {
              
              setState({
                ...state,
                primaryId: `${cate.primaryId}`,
                name: `${cate.name}`,
                description: `${cate.description}`,
                editclicked: true,
              })
            }}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
        )}

        <a
          href='#'
          onClick={handleShow}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          // onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{cate.name}" ?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {CategoryActionsCell}

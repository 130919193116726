import {useContext} from 'react'
import {AlertContext} from '../alert-context'

type MessageInfo = {
  type: string
  message: string
}

const AlertBox = (info: MessageInfo) => {
  const {hideAlert} = useContext(AlertContext)
  console.log('info', info)
  return info.type === 'success' ? (
    <div
      id='alertBox'
      className='alert border border-primary bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'
    >
      <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
        <i style={{fontSize: '2rem'}} className='text-primary bi bi-check-circle-fill'></i>
      </span>
      <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
        <h5 className='mb-1'>Success Message</h5>
        <span>{info.message}</span>
      </div>

      <button
        type='button'
        onClick={hideAlert}
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
      >
        <i className='bi bi-x fs-1 text-primary'></i>
      </button>
    </div>
  ) : (
    <div
      id='alertBox'
      className='alert border border-danger bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10'
    >
      <span className='svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0'>
        <i style={{fontSize: '2rem'}} className='text-danger bi bi-exclamation-circle-fill'></i>
      </span>
      <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
        <h5 className='mb-1'>Error Message</h5>
        <span>{info.message}</span>
      </div>

      <button
        type='button'
        onClick={hideAlert}
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
      >
        <i className='bi bi-x fs-1 text-primary'></i>
      </button>
    </div>
  )
}

export {AlertBox}

import axios from 'axios'
import {getToken} from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

const getData = (query: string) => {
  return axios.get(`${API_URL}/listReport/query?${query}`, getToken()).then((response: any) => {
    return response.data
  })
}

const reports = async (data?: any) => {
  const res = await axios.post(`${API_URL}/report`, data, getToken())

  return res
}

const getPlan = async () => {
  const res = await axios.get(`${API_URL}/plan/getPlan`, getToken())
  return res
}

const getPlanStatus = async () => {
  const res = await axios.get(`${API_URL}/planStatus`, getToken())
  return res
}

export {getPlan, getPlanStatus, reports, getData}

// @ts-nocheck
import {Column} from 'react-table'
import {ProductInfoCell} from './ProductInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {ProductActionsCell} from './ProductActionsCell'
import {ProductCustomHeader} from './ProductCustomHeader'
import {Product} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Product>> = [
  
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Product' className='min-w-125px' />
    ),
    accessor: 'product.productName',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Market' className='min-w-125px' />
    ),
    accessor: 'market.marketName',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'market.marketState.stateName',
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Quantity' className='min-w-125px' />
    ),
    accessor: 'units',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Measurement' className='min-w-125px' />
    ),
    accessor: 'price_measurement.measurementName',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Price' className='min-w-125px' />
    ),
    accessor: 'price',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'created_date',
    Cell: ({...props}) => <ProductInfoCell product={props.data[props.row.index]} columnAccessor = 'date' />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductActionsCell prod={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

type Props = {
  sell: any
  columnAccessor: string
}

// const EmployeeInfoCell: FC<Props> = ({sell}) => (
  const EmployeeInfoCell: FC<Props> = ({sell, columnAccessor}) => { 
  return(
  <>
    {columnAccessor === 'status' && (
       <div className='d-flex align-items-center'>
          <h6 className={`status-${sell?.status}`}>{sell?.status}</h6>
      </div>
      )}
      {columnAccessor === 'Start Date' && (
       <div className='d-flex align-items-center'>
         <h6 className={`status-${sell.created_date}`}>{sell.created_date}</h6>
      </div>
      )}
      {columnAccessor === 'End Date' && (
       <div className='d-flex align-items-center'>    
          <h6 className={`status-${sell.valid_date}`}>{sell.valid_date}</h6>
      </div>
      )}
  </>
    
  )}
 
export {EmployeeInfoCell}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteProduct, deleteProductPrice} from '../../core/_requests'
import {Product} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
  prod: Product
}

const ProductActionsCell: FC<Props> = ({prod}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const editEmployeeDetail = () => {
    navigate('/price/edit/' + prod.primaryId)
  }
  // const deleteItem = () => {
  //   deleteProduct(prod.primaryId).then((res) => {
  //     setShow(false)
  //     showAlert(res?.data?.Message, res?.data?.Code)
  //     navigate('/price')
  //   })
  // }

  const deleteItem = () => {
    deleteProductPrice(prod.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.data?.Message, res?.data?.Code)
      navigate('/price')
    })
  }
  
  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const {currentUser} = useAuth()
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 3).then((res: any) => {
      var crudres = res.data?.Data[0]
      // 
      setroleaccess(crudres)
    })
  }, [])
  return (
    <>
      {/* Edit Button */}
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {roleaccess?.edit === 1 && (
          <a
            onClick={editEmployeeDetail}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
        )}
        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Product Price</Modal.Title>
        </Modal.Header>
        <Modal.Header> */}
          {/* begin::Input group */}
          {/* <div className='fv-row mb-7'> */}
            {/* begin::Label */}
            {/* <label className='required fw-bold fs-6 mb-2'>Full Name</label> */}
            {/* end::Label */}

            {/* begin::Input */}
            {/* <input
              placeholder='Full name'
              type='text'
              name='name'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
            />
          </div>
        </Modal.Header> */}
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Modal */}

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{prod.product?.productName}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {ProductActionsCell}

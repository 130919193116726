/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import {viewData} from '../user-list/core/_requests'

export function UserPlan() {
  const {id} = useParams()
  const [data, setData] = useState<any>([])
  useEffect(() => {
    viewData(id).then((res: any) => {
      setData(res.data[0])
    })
  }, [])
  // Helper function to format dates
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString()
  }
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>User Plans</h3>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-340px'> Plan</th>
                      <th className='min-w-340px'> Amount</th>
                      <th className='min-w-340px'> Days</th>
                      <th className='min-w-100px'>Category</th>
                      <th className='min-w-100px'>Start Date</th>
                      <th className='min-w-100px'>End Date</th>
                      <th className='min-w-100px'>Expired</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.user_plan_details?.length > 0 ? (
                      data.user_plan_details.map((plan: any, index: number) => (
                        <tr key={index}>
                          <td>{plan.planName}</td>
                          <td>{plan.amount}</td>
                          <td>{plan.days}</td>
                          <td>{plan.planCategoryName}</td>
                          <td>{formatDate(plan.startDate)}</td>
                          <td>{formatDate(plan.endDate)}</td>
                          <td>
                            {plan.isExpired ? (
                              <span className='badge badge-danger'>Expired</span>
                            ) : (
                              <span className='badge badge-success'>Active</span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} className='text-center fw-bold'>
                          No records found
                        </td>{' '}
                        {/* Ensure the message spans all columns */}
                      </tr>
                    )}
                  </tbody>

                  {/* end::Table body */}
                </table>

                {/* end::Table */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

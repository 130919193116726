import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {IUpdatePassword, updatePassword as initialValues, updatePassword} from '../SettingsModel'
import {fetchProfile, updatePasswordInfo} from '../_requests'
import {useAuth} from '../../../../auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {AlertContext} from '../../../../../../_metronic/layout/components/alert-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const profileDetailsSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const Changepassword: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const [currentPassword, setCurrentPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [newPassword, setNewPassword] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  let passid = currentUser?.Id
  if (!passid) {
    passid = currentUser?.userId
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IUpdatePassword>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        actions.resetForm({
          values: initialValues,
        })
        const updatedData = Object.assign(values)
        updatePasswordInfo(updatedData, passid).then((res) => {
          showAlert(res?.Message, res?.Code)
          navigate('/changePassword/list', {replace: true})
        })

        setLoading(false)
      }, 1000)
    },
    enableReinitialize: true,
  })
  useEffect(() => {
    localStorage.setItem('PPshowAlert', 'null')
  }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Change Password Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className=' '>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                <span className=''>Current Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <input
               type={currentPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter your Current Password'
                  {...formik.getFieldProps('currentPassword')}
                />
                         <button
              type='button'
              style={{border :"1px solid #E4E6EF"}}
              className='btn btn-outline-secondary'
              onClick={() => setCurrentPassword(!currentPassword)}
            >
              <FontAwesomeIcon icon={currentPassword ? faEye : faEyeSlash} />
            </button>
            </div>
                {formik.touched.currentPassword && formik.errors.currentPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                <span className=''>New Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <input
                   type={newPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter your New Password'
                  {...formik.getFieldProps('newPassword')}
                />
                         <button
              type='button'
              style={{border :"1px solid #E4E6EF"}}
              className='btn btn-outline-secondary'
              onClick={() => setNewPassword(!newPassword)}
            >
              <FontAwesomeIcon icon={newPassword ? faEye : faEyeSlash} />
            </button>
            </div>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.newPassword}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                <span className=''>Password Confirmation</span>
              </label>

              <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <input
                 type={confirmPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter your Password for Confirmation'
                  {...formik.getFieldProps('passwordConfirmation')}
                />
                         <button
              type='button'
              style={{border :"1px solid #E4E6EF"}}
              className='btn btn-outline-secondary'
              onClick={() => setConfirmPassword(!confirmPassword)}
            >
              <FontAwesomeIcon icon={confirmPassword ? faEye : faEyeSlash} />
            </button>
            </div>
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Update Password'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export {Changepassword}

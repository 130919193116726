// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Title' className='min-w-125px' />
    ),
    accessor: 'title',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Image' className='min-w-125px' />
    ),
    accessor: 'short_image_name',
    Cell: ({...props}) => <EmployeeInfoCell news={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'news_category.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'location',
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell news={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Category, CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const USER_URL = `${API_URL}/category`
const CALL_API_URL = `${API_URL}/category`
const GET_CAT_URL = `${API_URL}/category/query`

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${API_URL}/feedbackCategory/query?${query}`)
    .then((d: AxiosResponse<CategoryQueryResponse>) => {
      
      return d.data
    })
}

const deleteCategoryById = (id: any) => {
  return axios
    .put(`${API_URL}/feedbackCategory/delete/${id}`, getToken())

    .then((response: any) => response.data)
}

const createCategoryMaster = (data: any) => {
  return axios
    .post(`${API_URL}/feedbackCategory/add`, data, getToken())
    .then((response: any) => response.data)
}

const updateCategoryMaster = (name: any, updatedBy: any, id: any) => {
  return axios
    .put(`${API_URL}/feedbackCategory/edit/${id}`, {name, updatedBy}, getToken())
    .then((response: any) => {
      
      return response.data
    })
} 
export {
  getCategory,
  
  createCategoryMaster,
  
  deleteCategoryById,
  updateCategoryMaster,
  
}

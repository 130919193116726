import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const USER_URL = `${API_URL}/category`

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${API_URL}/categoryMaster/query?${query}`)
    .then((d: AxiosResponse<CategoryQueryResponse>) => {
      
      return d.data
    })
}

const deleteCategoryById = (id: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/delete/${id}`, {}, getToken())

    .then((response: any) => response.data)
}

const createCategoryMaster = (data: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/add`, data, getToken())
    .then((response: any) => response.data)
}

const updateCategoryMaster = (name: any, updatedBy: any, id: any) => {
  return axios
    .put(`${API_URL}/categoryMasterUpdates/${id}`, {name, updatedBy}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}
const deleteCategoryMaster = (name: any, updatedBy: any, id: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/delete/${id}`, {}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const duplicateData = async (data: any) => {
  try {
    const response = await axios.post(`${API_URL}/categoryMaster/duplicate`, data, getToken());
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error checking duplicate data:", error);
    return null; // Handle error scenario, return null or any suitable value
  }
};

export {
  getCategory,
  deleteUser,
  createCategoryMaster,
  deleteSelectedUsers,
  deleteCategoryById,
  updateCategoryMaster,
  deleteCategoryMaster,
  duplicateData
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {getSettings, updateAppVersionSettings} from '../list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

export function ViewAppVersion() {
  const {showAlert} = useContext(AlertContext)
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [showModal, setShow] = useState(false)
  const [andCurversion, setandCurversion] = useState('')
  const [andMinversion, setandMinversion] = useState('')
  const [iosCurversion, setiosCurversion] = useState('')
  const [iosMinversion, setiosMinversion] = useState('')
  const [andurl, setandurl] = useState('')
  const [iosurl, setiosurl] = useState('')
  const handleClose = () => setShow(false)
  useEffect(() => {
    getInitialValues()
  }, [showModal])
  const getInitialValues = () => {
    getSettings().then((res: any) => {
      setData(res.data?.data[0])
      
      setandCurversion(res.data?.data[0]?.adroid_app_version)
      setandMinversion(res.data?.data[0]?.adroid_min_app_version)
      setiosCurversion(res.data?.data[0]?.ios_app_version)
      setiosMinversion(res.data?.data[0]?.ios_min_app_version)
      setandurl(res.data?.data[0]?.android_store_url)
      setiosurl(res.data?.data[0]?.ios_store_url)
    })
  }
  const handleEdit = () => {
    
    setShow(true)
  }
  const SubmitHandle = () => {
    const datas = {
      andCurrentVersion: andCurversion,
      andMinimumVersion: andMinversion,
      iosCurrentVersion: iosCurversion,
      iosMinimumVersion: iosMinversion,
      android_store_url: andurl,
      ios_store_url: iosurl,
      id: data?.id,
    }

    updateAppVersionSettings(datas).then((res) => {
      

      showAlert(res?.data?.Message, res?.data?.Code)
      getInitialValues()
      navigate('/settings/appVersion', {replace: true})
    })

    //
    setShow(false)
  }
  const handleInputandcur = (e: any) => {
    setandCurversion(e.target.value)
  }
  const handleInputandmin = (e: any) => {
    setandMinversion(e.target.value)
  }
  const handleInputioscur = (e: any) => {
    setiosCurversion(e.target.value)
  }
  const handleInputiosmin = (e: any) => {
    setiosMinversion(e.target.value)
  }
  const handleInputiosurl = (e: any) => {
    setiosurl(e.target.value)
  }
  const handleInputandurl = (e: any) => {
    setandurl(e.target.value)
  }
  const editrazar = 'settings/edit/' + data?.id

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>App Version Details</h3>
              </div>
              <button onClick={handleEdit} className='btn btn-primary align-self-center'>
                Edit
              </button>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Android Current Version</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.adroid_app_version}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Android Minimum Acceptable Version
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.adroid_min_app_version}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>iOS Current Version</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.ios_app_version}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  iOS Minimum Acceptable Version
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.ios_min_app_version}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Android Store Url</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.android_store_url}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>iOS Store Url</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.ios_store_url}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Android Current Version</label>

            <div className='col-lg-8'>
              <input
                type='float'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Android Current Version'
                value={andCurversion}
                onChange={(e) => {
                  handleInputandcur(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Android Minimum Acceptable Version
            </label>

            <div className='col-lg-8'>
              <input
                type='float'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Android Minimum Acceptable Version'
                value={andMinversion}
                onChange={(e) => {
                  handleInputandmin(e)
                }}
              />
            </div>
          </div>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>iOS Current Version</label>

            <div className='col-lg-8'>
              <input
                type='float'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter iOS Current Version'
                value={iosCurversion}
                onChange={(e) => {
                  handleInputioscur(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>iOS Minimum Acceptable Version</label>

            <div className='col-lg-8'>
              <input
                type='float'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Minimum Acceptable Version'
                value={iosMinversion}
                onChange={(e) => {
                  handleInputiosmin(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'> Android Store Url</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Android Store Url'
                value={andurl}
                onChange={(e) => {
                  handleInputandurl(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>iOS Store Url</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter iOS Store Url'
                value={iosurl}
                onChange={(e) => {
                  handleInputiosurl(e)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={SubmitHandle}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'

type Props = {
  news: News
}

const EmployeeInfoCell: FC<Props> = ({news}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    {news?.short_image_name ? (
      <img src={news?.short_image_name} height='100' width='100' />
    ) : (
      <img src={toAbsoluteUrl('/media/avatars/noImage.jpg')} height='100' width='100' />
    )}
  </div>
)

export {EmployeeInfoCell}

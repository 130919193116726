import axios, {AxiosResponse} from 'axios'
 
import {Product, ProdcutQueryResponse} from './_models'
import { getToken } from '../../../../auth'
 

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

const getProduct = (query: string): Promise<ProdcutQueryResponse> => {
  return axios
    .get(`${API_URL}/more/listFeedback/query?${query}`,getToken())
    .then((response: AxiosResponse<ProdcutQueryResponse>) => {
      
      return response.data
    })
}
const category = () => {
  return axios.get(`${API_URL}/feedbackCategory`, getToken()).then((response) => {
    return response.data
  })
}

export { getProduct,category }

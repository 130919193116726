/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import '../buy.css'
import {viewData} from '../buy-list/core/_requests'
export function View() {
  const {id} = useParams()

  const [buy, setBuy] = useState<any>([])

  useEffect(() => {
    viewData(id).then((res: any) => {
      
      setBuy(res.data[0])
    })
  }, [])
  var editsubsob = '/buyer-request/edit/' + id

  //remove client

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Buyer Request Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Buyer Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {buy?.user?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{buy?.buysell_category?.name} </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Product</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {buy?.ProductMaster?.name} </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Quantity</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {buy?.qty}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Price</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {buy?.price_per_unit} / {buy?.measurement?.name}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Area</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{buy?.pincode?.region}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Notes</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{buy?.about_product}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Status</label>
            <div className='col-lg-8'>
              <span className={`fw-bolder fs-6   status-${buy?.status}`}>{buy?.status}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {Field, useFormik} from 'formik'
import {createNews, getCategory} from '../news-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../news-list/core/QueryResponseProvider'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

//Model Interface
export interface IProfileDetails {
  title: string
  categories: string
  location: string
  short_message: string
  long_message: string
  short_image_name: any
  formImage: any
  createdBy: string | undefined
}

//Model Initial Value
export let initialValues: IProfileDetails = {
  title: '',
  categories: '',
  location: '',
  short_message: '',
  long_message: '',
  short_image_name: '',
  formImage: '',

  createdBy: '',
}

//Validation
const roleDetailsSchema = Yup.object().shape({
  title: Yup.string().required('Title  is required'),
  categories: Yup.string().required('Category is required'),
  location: Yup.string().required('Location is required'),
  short_message: Yup.string().required('Give some short description'),
  long_message: Yup.string().required('Description is required'),
})

//Function Starts
const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)
  const [preview, setpreview] = useState<string | ArrayBuffer | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Image Handler
  const imageHandler = (e: any) => {
    
    
    

    const reader = new FileReader()
    reader.onload = () => {
      setpreview(reader.result)
      
      if (reader.readyState === 2) {
        setData({
          ...data,
          short_image_name: reader.result,
        })
      }
    }
    const files = e.target.files
    if (files) {
      
      setFormImage(files[0])
      
      reader.readAsDataURL(files[0])
    }
  }

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const type = currentUser?.type
  const [loading, setLoading] = useState(false)

  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId

      //Image and data Append to Form Data
      const formData = new FormData()
      
      //image append to formData
      if (formImage) formData.append('short_image_name', formImage)

      //updatedData append to formData
      Object.entries(updatedData).forEach(([key, value]) => formData.append(key, value))

      createNews(formData).then((res) => {
        
        initialValues = {
          title: '',
          categories: '',
          location: '',
          short_message: '',
          long_message: '',
          short_image_name: '',
          formImage: '',
          createdBy: '',
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/list-news', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    },
    enableReinitialize: true,
  })

  // Get cat using type
  const [cat, setCat] = useState<any>([])
  useEffect(() => {
    getCategory().then((res: any) => {
      
      setCat(res?.data?.Data)
    })
  }, [])
  
  const handleDescriptionChange = (value: any) => {
    formik.setFieldValue('long_message', value)
  }
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add News </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Title</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Title '
                    {...formik.getFieldProps('title')}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Category</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('categories')}
                  >
                    <option value=''>Select a Category</option>
                    {cat.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.categories && formik.errors.categories && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.categories}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Location</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter News Location'
                    {...formik.getFieldProps('location')}
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.location}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Short Message</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Short Message'
                    {...formik.getFieldProps('short_message')}
                  ></textarea>
                  {formik.touched.short_message && formik.errors.short_message && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.short_message}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6  '>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'> Image</label>
                  {/* <input
                    type='file'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Email'
                    {...formik.getFieldProps('short_image_name')}
                    onChange={(e) => imageHandler(e)}
                  /> */}
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    {typeof data?.short_image_name === 'string' ? (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${data.short_image_name})`}}
                      ></div>
                    ) : (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      ></div>
                    )}
                    <label
                      className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      data-bs-dismiss='click'
                      title='Choose image'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>
                      <input
                        type='file'
                        name='avatar'
                        onChange={imageHandler}
                        accept='.png, .jpg, .jpeg'
                      />
                    </label>
                    {/* <!--end::Edit button--> */}
                    {/* <!--begin::Remove button--> */}
                    {data.short_image_name && (
                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        onClick={() => {
                          setData({
                            ...data,
                            short_image_name: null,
                          })
                        }}
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Remove Profile image'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    )}
                  </div>
                  {data.short_image_name ? (
                    ''
                  ) : (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Please upload image</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Description</label>
                  <ReactQuill
                    value={formik.values.long_message}
                    onChange={handleDescriptionChange}
                  />
                  {formik.touched.long_message && formik.errors.long_message && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.long_message}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

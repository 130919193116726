import axios, {AxiosResponse} from 'axios'
import { getToken } from '../../modules/auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS
const DASH_URL=`${API_URL}/dashboard`

const getBuyerProductsWithCount = () => {
  return axios.get(`${DASH_URL}/request-buy-count`, getToken()).then((response) => {
    return response.data
  })
}
const getSellerProductsWithCount = () => {
  return axios.get(`${DASH_URL}/request-sell-count`, getToken()).then((response) => {
    return response.data
  })
}
const getUserCountStateWise = () => {
  return axios.get(`${DASH_URL}/statewise-user-count`, getToken()).then((response) => {
    return response.data
  })
}
const PlanActiveUserCount = () => {
  return axios.get(`${DASH_URL}/plan-active-user`, getToken()).then((response) => {
    return response.data
  })
}
const getUserTypeCount = () => {
  return axios.get(`${DASH_URL}/user-type-count`, getToken()).then((response) => {
    return response.data
  })
}
const getCurrentMonthCount = () => {
  return axios.get(`${DASH_URL}/current-month-count`, getToken()).then((response) => {
    return response.data
  })
}
const getUserGraph = (data :any) => {
  return axios.post(`${DASH_URL}/user-graph`, data, getToken()).then((response) => {
    return response.data
  })
}

export {
  getBuyerProductsWithCount,
  getSellerProductsWithCount,
  getUserCountStateWise,
  PlanActiveUserCount,
  getUserTypeCount,
  getCurrentMonthCount,
  getUserGraph
}

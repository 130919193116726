import axios, {AxiosResponse} from 'axios'
import {UserTypeQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
 

const getUserType = (query: string): Promise<UserTypeQueryResponse> => {
  return axios
    .get(`${API_URL}/users/user-type/list/query?${query}`)
    .then((d: AxiosResponse<UserTypeQueryResponse>) => {
      console.log("fjjdnfknjkdnkfdjnjkfd",d.data);
      
      return d.data
    })
}
const deleteUserTypeById = (id: any) => {
  return axios
    .put(`${API_URL}/users/user-type/delete/${id}`, getToken())

    .then((response: any) => response.data)
}

const createUserType = (data: any) => {
  
  return axios
    .post(`${API_URL}/users/user-type/add`, data, getToken())
    .then((response: any) => response.data)
}

const updateUserType = (data: any,id:any) => {
  return axios
    .put(`${API_URL}/users/user-type/update/${id}`,  data, getToken())
    .then((response: any) => {
      
      return response.data
    })
} 

const duplicateData = async (data: any) => {
  try {
    const response = await axios.post(`${API_URL}/users/user-type/duplicate`, data, getToken());
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error checking duplicate data:", error);
    return null; // Handle error scenario, return null or any suitable value
  }
};

export {
  getUserType,
  deleteUserTypeById,
  createUserType,
  updateUserType,
  duplicateData
}

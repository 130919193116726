import { Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import { createContext, useState } from 'react'

// Create the context with both email and setEmail
export const MailContext = createContext({ email: '', setEmail: (email: string) => {} });

const AuthPage = () => {
  const [email, setEmail] = useState('')

  return (
    // Make sure the properties match `email` and `setEmail`
    <MailContext.Provider value={{ email, setEmail }}>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<Login />} />
          {/* <Route path='registration' element={<Registration />} /> */}
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route index element={<Login />} />
        </Route>
      </Routes>
    </MailContext.Provider>
  )
}

export { AuthPage }

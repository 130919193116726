/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {News} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import '../../../buy.css'
type Props = {
  buy: any,
  columnAccessor: string
}

const EmployeeInfoCell: FC<Props> = ({buy, columnAccessor}) => {
  return(<>
     {columnAccessor === 'status' && (
        <div className='d-flex align-items-center'>
         <h6 className={`status-${buy?.status}`}>{buy?.status}</h6>
       </div>
     )}
     {columnAccessor === 'Start Date' && (
        <div className='d-flex align-items-center'>
         <h6 className={`status-${buy.created_date}`}>{buy.created_date}</h6>
       </div>
     )}
     {columnAccessor === 'End Date' && (
        <div className='d-flex align-items-center'>
         <h6 className={`status-${buy.valid_date}`}>{buy.valid_date}</h6>
       </div>
     )}

  </>)
}
 

export {EmployeeInfoCell}

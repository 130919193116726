import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewPrice} from '../productprice-list/core/_requests'
import {EditProduct} from '../productprice-form/EditProduct'

export const EditProductWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewPrice(id).then((res) => {
      setData(res?.data?.Data)
    })
  }, [])
  if (data) {
    return <EditProduct />
  }
  return null
}

import axios, {AxiosResponse} from 'axios'
 
import {Product, ProdcutQueryResponse} from './_models'
import { getToken } from '../../../../../auth'
 

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

const getProduct = (query: string,id:any): Promise<ProdcutQueryResponse> => {
  return axios
    .post(`${API_URL}/listSubscription/query?${query}`, {id},getToken())
    .then((response: AxiosResponse<ProdcutQueryResponse>) => {
      console.log("subscription",response.data);
      
      return response.data
    })
}

export { getProduct, }

import {ReportContent} from './table/ReportContent'
import {KTCard} from '../../../../../_metronic/helpers'

const Report = () => {
  return (
    <>
      <KTCard>
        <ReportContent />
      </KTCard>
    </>
  )
}

const ReportWrapper = () => (

  <Report />
      
)

export {ReportWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Market} from '../../core/_models'
import Avatar from 'react-avatar'

type Props = {
  Plan: any
}

const EmployeeInfoCell: FC<Props> = ({Plan}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    {Plan?.user_type == '1' ? 'Free Plan' : 'Payment Plan'}
  </div>
)

export {EmployeeInfoCell}

import {CategoryListHeader} from './components/header/CategoryListHeader'
import {CategoryTable} from './table/CategoryTable'
import {KTCard} from '../../../../../_metronic/helpers'

import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {ContextProvider} from './ContextProvider'

const CategoryList = () => {
  return (
    <>
      <KTCard>
        <CategoryListHeader />
        <CategoryTable />
      </KTCard>
    </>
  )
}
const MainPage = () => {
  const {show, message, type} = useContext(AlertContext)
  

  return (
    <>
      {show && <AlertBox type={type == '1' ? 'success' : 'error'} message={message} />}

      <CategoryList />
    </>
  )
}

const CategoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <MainPage />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CategoryListWrapper}

import {useEffect, useState, useRef} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import DatePicker from 'react-datepicker'
import {getFilterProduct, getFilterMarket } from '../../core/_requests'

const EmployeeListFilter = () => {
  const tableRef = useRef(null)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [product, setProduct] = useState<any>([])
  const [market, setMarket] = useState<any>([])
  const [productid, setProductId] = useState<string | undefined>()
  const [marketid, setMarketId] = useState<string | undefined>()
  
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const filterData = () =>{
    var date = (document.getElementById('Daterange') as HTMLInputElement).value
    updateState({
      filter: { productid, marketid,date},
      ...initialQueryState,
    })

  }

   const resetData =()=>{
    updateState({
      filter: {filter: undefined, ...initialQueryState},
        ...initialQueryState,
    })
    setProductId('');
    setMarketId('');
    setDateRange([null, null]);
   }
  
  useEffect(() => {
    getFilterProduct().then((res: any) => {
      setProduct(res.data.data)
    })

    getFilterMarket().then((res: any) => {
      setMarket(res.data.data)
    })
    
  }, [])

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true' style={{ maxHeight: 'calc(400px - 80px)', overflowY: 'auto' }}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

         {/* begin::product filter */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'> Product:</label>
            <select
              className='form-select form-select-solid fw-bolder'
            
              onChange={(e) => setProductId(e.target.value)}
              value={productid}
            >
              <option value=''>All</option>
              {product?.map((item: any, i: any) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
          {/* end::product filter */}
          {/* begin::market filter */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'> Market:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              onChange={(e) => setMarketId(e.target.value)}
              value={marketid}
            >
              <option value=''>All</option>
              {market?.map((item: any, i: any) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
            {/* end::market filter */}
            {/* begin::datefilter */}
          <div className='mb-10'>
              <label className=' col-form-label fw-bold fs-6'>Date Range :</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any, e:any) => {
                  setDateRange(update)
                  e.stopPropagation()
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                
              />
            </div>
             {/* begin::datefilter */}
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Clear
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {EmployeeListFilter}

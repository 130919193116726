import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useEffect, useState} from 'react'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import { EmployeeListFilter } from './EmployeeListFilter'

const EmployeeListToolbar = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const addProductPage = () => {
    navigate('/product/add')
  }
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 3).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
<EmployeeListFilter/>
      {/* end::Add user */}
    </div>
  )
}

export {EmployeeListToolbar}

import {Route, Routes, Outlet, Navigate} from 'react-router-dom'

import {ListWrapper} from './list/EmployeeList'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CategoryListWrapper} from '../feebcack-category/category-list/CategoryList'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'FeedbackPage Management',
    path: '/feedback',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FeedbckPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>FeedbackPage list</PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path='/categoryList'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Feedback Category</PageTitle>
              <CategoryListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default FeedbckPage

import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {Field, useFormik} from 'formik'
import {createData, getCategory, getUserType} from '../plan-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../plan-list/core/QueryResponseProvider'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

//Model Interface
export interface IProfileDetails {
  name?: string
  payment_type?: string
  user_type?: string
  amount?: string
  days?: string
  advertisement?: string
  description?: string
  status?: any
  is_popular: string
  Message?: string
  Code?: string
  employee_id?: string | undefined
  buyerCount: string
  sellerCount: string
}

//Model Initial Value
export let initialValues: IProfileDetails = {
  name: '',
  payment_type: '',
  user_type: '',
  amount: '',
  is_popular: '1',
  days: '',
  advertisement: '',
  description: '',
  status: '',
  Message: '',
  Code: '',
  employee_id: '',
  buyerCount: '',
  sellerCount: '',
}

//Validation
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name  is required'),
  amount: Yup.string().required('Amount is required'),
  payment_type: Yup.string().required('Payment is required'),
  // days: Yup.string().required('Days is required'),
  days: Yup.number()
    .required('Plan Validity Days is required')
    .positive('Salary must be a positive number')
    .integer('Salary must be an integer'),
  description: Yup.string().required('Give some short description'),
  user_type: Yup.string().required('User type is required'),
  buyerCount: Yup.string().required('Buyer count is required'),
  sellerCount: Yup.string().required('Seller count is required'),
})
//Function Starts
const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.employee_id = currentUser?.userId

      
      createData(updatedData).then((res) => {
        
        initialValues = {
          name: '',
          payment_type: '',
          user_type: '',
          is_popular: '1',
          amount: '',
          days: '',
          advertisement: '',
          description: '',
          buyerCount: '',
          sellerCount: '',
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/plan', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    },
    enableReinitialize: true,
  })
  const [userType, setUserType] = useState<any>([])
  useEffect(() => {
    getUserType().then((res: any) => {
      setUserType(res?.data)
    })
  }, [])
  console.log(formik);
  

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Plan </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Name '
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan Type</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('payment_type')}
                  >
                    <option value=''>Select a Type</option>

                    <option value='1'>Free Plan</option>
                    <option value='0'>Payment Plan</option>
                  </select>
                  {formik.touched.payment_type && formik.errors.payment_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.payment_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan Category</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('user_type')}
                  >
                    <option value=''>Select a Type</option>

                    {userType.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.user_type && formik.errors.user_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.user_type}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Days</label>
                  <input
                    type='number'
                    min='0'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('days')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.days && formik.errors.days && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.days}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Amount</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Amount'
                    {...formik.getFieldProps('amount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Description</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Description'
                    {...formik.getFieldProps('description')}
                  ></textarea>
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Buyer Post Count / Day
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter buyer post count / day'
                    {...formik.getFieldProps('buyerCount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.buyerCount && formik.errors.buyerCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.buyerCount}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Seller Post Count / Day
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter seller  post count / day'
                    {...formik.getFieldProps('sellerCount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.sellerCount && formik.errors.sellerCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.sellerCount}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Popular </label>
                  <div className='d-flex align-items-center mt-3'>
                    <label className='form-check form-check-inline form-check-solid'>
                      <input
                        type='radio'
                        className='form-check-input'
                        {...formik.getFieldProps('is_popular')}
                        value='1'
                        checked
                      />
                      <span className='text-muted ps-2 fs-6'>Popular</span>
                    </label>
                    <label className='form-check form-check-inline form-check-solid me-5'>
                      <input
                        type='radio'
                        className='form-check-input'
                        {...formik.getFieldProps('is_popular')}
                        value='0'
                      />
                      <span className='text-muted ps-2 fs-6'>Not Popular</span>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

import React from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {MyContext} from '../../ContextFile'
import {useListView} from '../../core/ListViewProvider'
import {CategoryListFilter} from './CategoryListFilter'
import {useNavigate} from 'react-router-dom'

const CategoryListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const Navigate = useNavigate()
  const {state, setState} = React.useContext(MyContext)
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
    Navigate('/product-category/add')
  }
  const onClickCateoryMaster = () => {
    Navigate('/categoryMaster')
  }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <CategoryListFilter /> */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary me-5' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Category
      </button> */}

      {/* end::Add user */}
    </div>
  )
}

export {CategoryListToolbar}

import axios, { AxiosResponse } from "axios";
import { Response } from "../../../../../../_metronic/helpers";
import { Market, EmployeeQueryResponse } from "./_models";
import { getToken } from "../../../../auth";

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS;

//AuthVerify
var AuthVerify = getToken.call(this);

//EMPLOYEE LIST
const getMarket = (query: string): Promise<EmployeeQueryResponse> => {
  return axios
    .get(`${API_URL}/plan/popularPlan/query?${query}`, getToken())
    .then((response: AxiosResponse<EmployeeQueryResponse>) => {
      
      return response.data;
    });
};

const getPlan = async () => {
  const res = await axios.get(`${API_URL}/plan/getPlan`, getToken());

  return res;
};
const updatePopularPlan = async (id: any, type: any) => {
  // 
  const res = await axios.post(
    `${API_URL}/plan/updatePopularPlan`,
    { id, type },
    getToken()
  );
  // 
  return res;
};
const getState = async (id: any) => {
  const res = await axios.get(`${API_URL}/market/listState/${id}`, getToken());

  return res;
};
const createMarket = async (data: any) => {
  const res = await axios.post(`${API_URL}/market/addMarket`, data, getToken());

  return res;
};
const getCity = async (id: any) => {
  const res = await axios.get(`${API_URL}/market/listCity/${id}`, getToken());

  return res;
};
const viewMarket = async (id: any) => {
  const res = await axios.get(`${API_URL}/market/viewMarket/${id}`, getToken());

  return res;
};
const getProductByCat = async (catId: any) => {
  const res = await axios.get(
    `${API_URL}/listproductBycategory/${catId}`,
    getToken()
  );

  return res;
};
const updateMarket = async (data: any, id: any) => {
  const res = await axios.put(
    `${API_URL}/market/updateMarket/${id}`,
    data,
    getToken()
  );

  return res;
};
const deleteMarket = async (id: any) => {
  const res = await axios.put(
    `${API_URL}/market/deleteMarket/${id}`,
    {},
    getToken()
  );

  return res;
};
const addmarketProduct = async (data: any) => {
  const res = await axios.post(
    `${API_URL}/addproductBycategory`,
    data,
    getToken()
  );

  return res;
};
const getProductMarket = async (id: any) => {
  const res = await axios.get(`${API_URL}/listMarketProduct/${id}`, getToken());

  return res;
};
const removeProductMarket = async (id: any) => {
  const res = await axios.put(
    `${API_URL}/RemoveMarketProduct/${id}`,
    getToken()
  );

  return res;
};
const checkExisingProduct = async (data: any) => {
  const res = await axios.post(
    `${API_URL}/checkExisingProduct`,
    data,
    getToken()
  );
  return res.data;
};

export {
  getMarket,
  getPlan,
  getState,
  getCity,
  createMarket,
  viewMarket,
  updateMarket,
  updatePopularPlan,
  deleteMarket,
  getProductByCat,
  addmarketProduct,
  getProductMarket,
  removeProductMarket,
  checkExisingProduct,
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {getrazarpay, getsmsgateway, updatesmsgateway} from '../list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

export function ViewSMS() {
  const {showAlert} = useContext(AlertContext)
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [showModal, setShow] = useState(false)
  const [TkeyVal, setTkeyVal] = useState('')
  const [SkeyVal, setSkeyVal] = useState('')
  const [AkeyVal, setAkeyVal] = useState('')
  const [Mes, setMes] = useState('')
  const handleClose = () => setShow(false)
  useEffect(() => {
    getsmsgateway().then((res: any) => {
      setData(res.data?.data[0])
      setTkeyVal(res.data?.data[0]?.msg91_templateID)
      setAkeyVal(res.data?.data[0]?.msg91_authkey)
      setSkeyVal(res.data?.data[0]?.msg91_sender_id)
      setMes(res.data?.data[0]?.otpcontent)
    })
  }, [showModal])

  const handleEdit = () => {
    
    setShow(true)
  }
  const SubmitHandle = () => {
    const datas = {
      auth: AkeyVal,
      template: TkeyVal,
      otpcontent: Mes,
      sender_id: SkeyVal,
      id: data?.id,
    }

    updatesmsgateway(datas).then((res) => {
      

      showAlert(res?.data?.Message, res?.data?.Code)
      navigate('/settings/smsGateway', {replace: true})
    })

    
    setShow(true)
  }
  const handleInputAKey = (e: any) => {
    setAkeyVal(e.target.value)
  }
  const handleInputTKey = (e: any) => {
    setTkeyVal(e.target.value)
  }
  const handleInputMesKey = (e: any) => {
    setMes(e.target.value)
  }
  const handleInputSKey = (e: any) => {
    setSkeyVal(e.target.value)
  }
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Details</h3>
              </div>
              <button onClick={handleEdit} className='btn btn-primary align-self-center'>
                Edit
              </button>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Auth Key</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.msg91_authkey}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Template ID</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.msg91_templateID}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Sender ID</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.msg91_sender_id}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>SMS Contents</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.otpcontent}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Key</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Auth Key'
                value={AkeyVal}
                onChange={(e) => {
                  handleInputAKey(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Template ID</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Template Key'
                value={TkeyVal}
                onChange={(e) => {
                  handleInputTKey(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Sender ID</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Sender Id '
                value={SkeyVal}
                onChange={(e) => {
                  handleInputSKey(e)
                }}
              />
            </div>
          </div>
          <div className='row  col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>OTP Content</label>

            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter OTP content'
                value={Mes}
                onChange={(e) => {
                  handleInputMesKey(e)
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={SubmitHandle}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

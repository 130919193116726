// @ts-nocheck
import {Column} from 'react-table'

import {UserTypeActionsCell} from './UserTypeActionsCell'
import {UserTypeCustomHeader} from './UserTypeCustomHeader'
import {Category} from '../../core/_models'
import { UserTypeInfoCell } from './UserTypeInfoCell'

const usersColumns: ReadonlyArray<Column<Category>> = [
  {
    Header: (props) => (
      <UserTypeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <UserTypeCustomHeader tableProps={props} title='Image' className='min-w-125px' />
    ),
    accessor: 'icon',
    Cell: ({...props}) => <UserTypeInfoCell  userType={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => (
      <UserTypeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <UserTypeCustomHeader tableProps={props} title='User Count' className='min-w-125px' />
    ),
    accessor: 'userCount',
  },

  {
    Header: (props) => (
      <UserTypeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserTypeActionsCell
        id={props.data[props.row.index].id}
        category={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}

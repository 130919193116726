import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Employee, EmployeeQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_EMPLOYEE_URL = `${API_URL}/users/add`
const GET_EMPLOYEE_URL = `${API_URL}/users/query`
const VIEW_EMPLOYEE_URL = `${API_URL}/users/view`
const UPDATE_EMPLOYEE_URL = `${API_URL}/users/update`
const DELETE_EMPLOYEE_URL = `${API_URL}/users/delete`
const GET_ROLE_URL = `${API_URL}/users/getRole`

//AuthVerify
var AuthVerify = getToken.call(this)

//EMPLOYEE LIST
const getEmployee = (query: string): Promise<EmployeeQueryResponse> => {
  return axios
    .post(`${GET_EMPLOYEE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<EmployeeQueryResponse>) => {
      return response.data
    })
}

//EMPLOYEE ADD
const createEmployee = (data: FormData): Promise<Employee | undefined> => {
  return axios.post(CREATE_EMPLOYEE_URL, data, getToken()).then((response: any) => response.data)
}

//EMPLOYEE VEIW
const viewEmployee = (id: any) => {
  return axios.put(`${VIEW_EMPLOYEE_URL}/${id}`, getToken()).then((response) => {
    return response.data
  })
}

//EMPLOYEE UPDATE
const updateEmployee = (data: FormData, id: any) => {
  
  return axios.put(`${UPDATE_EMPLOYEE_URL}/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}

//EMPLOYEE DELETE
const deleteEmployeeById = (id: any) => {
  return axios.post(`${DELETE_EMPLOYEE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

//GET ROLE
const getRole = async (type: any) => {
  const res = await axios.get(`${GET_ROLE_URL}/${type}`, getToken())
  // 
  return res
}

const getProductDeatils = async (id: any) => {
  const res = await axios.get(`${API_URL}/getProductwisePrice/${id}`, getToken())
  
  return res
}

export {
  getEmployee,
  createEmployee,
  viewEmployee,
  updateEmployee,
  deleteEmployeeById,
  getRole,
  getProductDeatils,
}

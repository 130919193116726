import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CategoryListFilter} from './CategoryListFilter'
import {Button, Modal} from 'react-bootstrap'
import {useContext, useEffect, useState} from 'react'
import {createCategoryMaster} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'

const CategoryListToolbar = () => {
  const {showAlert} = useContext(AlertContext)
  const {setItemIdForUpdate} = useListView()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {refetch} = useQueryResponse()
  const [showModal, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [ErrorName, setErrorName] = useState('')
  const [ErrorEmp, setErrorEmp] = useState('')
  const [incharge, setIncharge] = useState('')
  const [Image, setImage] = useState<any>()
  const [roleaccess, setroleaccess] = useState<any>([])
  const [formImage, setFormImage] = useState<File | null>(null)
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 6).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])

  const handleClose = () => {
    setShow(false)
  }
  const openAddUserModal = () => {
    setShow(true)
  }
  const submitData = () => {
    const updatedData = {
      name: name,
      createdBy: currentUser?.userId,
    }
    if (name !== '') {
      setLoading(true)
      createCategoryMaster(updatedData).then((res: any) => {
        navigate('/userplantype', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    } else {
      if (name === '') {
        setErrorName('Category name is required')
      }
     
    }
  }
  
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {roleaccess?.add === 1 ? (
          <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Category
          </button>
        ) : (
          ''
        )}

        {/* end::Add user */}
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Category Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Category  Name'
                  id='Name'
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setErrorName('')
                  }}
                />
                {ErrorName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorName}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={submitData}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {CategoryListToolbar}

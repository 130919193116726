import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Plan, QueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

 

//AuthVerify
var AuthVerify = getToken.call(this)

// LIST
const getData = (query: string): Promise<QueryResponse> => {
  return axios
    .get(`${API_URL}/plan/query?${query}`, getToken())
    .then((response: AxiosResponse<QueryResponse>) => {
      return response.data
    })
}

// ADD
const createData = (data:any) => {
  return axios.post(`${API_URL}/plan/add`, data, getToken()).then((response: any) => response.data)
}

// VEIW
const viewData = (id: any) => {
  return axios.get(`${API_URL}/plan/view/${id}`, getToken()).then((response) => {
    return response.data
  })
}
const getUserType = () => {
  return axios.get(`${API_URL}/plan/listPlanType`, getToken()).then((response) => {
    console.log("list user type in plan",response.data );
    console.log("list user type in plan",response );
    
    return response.data
  })
}
// UPDATE
const updateData = (data: any, id: any) => {
  
  return axios.put(`${API_URL}/plan/edit/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}

// DELETE
const deleteById = (id: any) => {
  return axios.put(`${API_URL}/plan/delete/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

//GET Category
const getCategory = async () => {
  const res = await axios.get(`${API_URL}/news/NewsCategory`, getToken())
  
  return res
}

export {getData, createData, viewData, updateData, deleteById, getCategory,getUserType}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import { getSubscription } from '../list/core/_requests'
import { dateFormat } from '../../../auth'
export function View() {
  const {id} = useParams()

  const [Subscription, setSubscription] = useState<any>([])

  useEffect(() => {
    getSubscription(id).then((res: any) => {
    console.log("res.Data",res.data);
      setSubscription(res.data)
    })
  }, [])

  console.log("===============================================");
  console.log("value", Subscription);
  console.log("===============================================");
  

  //remove client

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Subscription Details</h3>
          </div>
          {/* <Link to={editPage} className='btn btn-primary align-self-center'>
            Edit Subscription
          </Link> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>User Id</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user_id}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Mobile</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user?.mobile}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user?.user_plan_details[0]?.plan?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan Days</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user?.user_plan_details[0]?.plan?.days}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Receipt</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.razorpay_payment_id} </span>
            </div>
          </div>
          
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan Start</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {dateFormat(Subscription?.user?.user_plan_details[0]?.startDate)}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan End</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {dateFormat(Subscription?.user?.user_plan_details[0]?.endDate)} </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Status</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {Subscription?.user?.plan_status.plan_status} </span>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

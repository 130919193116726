// @ts-nocheck
import {Column} from 'react-table'
import {MarketInfoCell} from './MarketInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import { Market } from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Market>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'location',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='City' className='min-w-125px' />
    ),
    accessor: 'marketCity.cityName',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'marketState.stateName',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Country' className='min-w-125px' />
    ),
    accessor: 'marketCountry.countryName',
  },
  // {
  //   Header: (props) => (
  //     <CategoryCustomHeader tableProps={props} title='Status' className='min-w-110px' />
  //   ),
  //   // accessor: 'enable_status',
  //     Cell: ({...props}) => <CategoryInfoCell cat={props.data[props.row.index]}   columnAccessor='status'/>,
  // },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'enable_status',
    Cell: ({...props}) => < MarketInfoCell employees={props.data[props.row.index]}   columnAccessor='status'/>,
    
  },
    
  

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell employees={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}

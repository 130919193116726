// @ts-nocheck
import {Column} from 'react-table'
import {
  EmployeeInfoCell,
  EmployeePlanStartCell,
  EmployeePlanEndCell,
  EmployeePlanRegCell,
  EmployeePlanCell,
} from './EmployeeInfoCell'

import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  // {
  //   Header: (props) => (
  //     <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
  //   ),
  //   accessor: 'serialNo',
  // },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='UID' className='min-w-125px' />
    ),
    accessor: 'primaryId',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='User Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Register Date' className='min-w-125px' />
    ),
    accessor: 'created',
    Cell: ({...props}) => <EmployeePlanRegCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'mobile',
  },
 
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => <EmployeeInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='User Type' className='min-w-125px' />
    ),
    accessor: 'userType.name',
  },

  
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell buy={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}

import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {News, NewsQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

//GET smsgateway
const getsmsgateway = async () => {
  const res = await axios.get(`${API_URL}/smsgateway`, getToken())

  return res
}
//GET razarpay
const getrazarpay = async () => {
  const res = await axios.get(`${API_URL}/razarpay`, getToken())

  return res
}
const updaterazarpay = async (data: any) => {
  const res = await axios.post(`${API_URL}/updateRazarpay`, data, getToken())

  return res
}
const updatesmsgateway = async (data: any) => {
  const res = await axios.post(`${API_URL}/updateSmsgateway`, data, getToken())

  return res
}
const getSettings = async () => {
  const res = await axios.get(`${API_URL}/settings`, getToken())

  return res
}
const updateSettings = async (data: any) => {
  const res = await axios.post(`${API_URL}/updateSettings`, data, getToken())

  return res
}
const listPlanType = async () => {
  const res = await axios.get(`${API_URL}/plan/listPlanType`, getToken())

  return res
}
const listPlan = async () => {
  const res = await axios.get(`${API_URL}/plan/listPlan`, getToken())

  return res
}
const getscreenaccess = async (plan_type: any) => {
  const res = await axios.get(`${API_URL}/AdminScreenAccess/${plan_type}`, getToken())
console.log("====>",res,"====>");

  return res
}
const submitAccess = async (module: any) => {
  const res = await axios.post(`${API_URL}/UpdateAdminScreenAccess`, module, getToken())

  return res
}
const updateAppVersionSettings = async (data: any) => {
  const res = await axios.put(`${API_URL}/updateAppVersionSettings`, data, getToken())

  return res
}
export {
  getsmsgateway,
  getrazarpay,
  updaterazarpay,
  updatesmsgateway,
  getSettings,
  updateSettings,
  listPlanType,
  getscreenaccess,
  submitAccess,
  updateAppVersionSettings,
  listPlan
}

import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Product, ProdcutQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

const getProduct = (query: string): Promise<ProdcutQueryResponse> => {

  return axios
    .get(`${API_URL}/price/query?${query}`, getToken())
    .then((response: AxiosResponse<ProdcutQueryResponse>) => {
      
      return response.data
    })
}

const getCategory = async () => {
  const res = await axios.get(`${API_URL}/product/listCategory`, getToken())
  // 
  return res
}

const getMeasurements = async () => {
  const res = await axios.get(`${API_URL}/unit/getMeasurement`, getToken())
  
  return res
}
const createProduct = async (data: FormData) => {
  const res = await axios.post(`${API_URL}/product/addProduct`, data, getToken())
  
  return res
}
const viewPrice = async (id: any) => {
  const res = await axios.get(`${API_URL}/price/viewPrice/${id}`, getToken())
  
  return res
}
const updateProduct = async (data: FormData, id: any) => {
  const res = await axios.post(`${API_URL}/product/updateProduct/${id}`, data, getToken())
  
  return res
}
const deleteProduct = async (id: any) => {
  const res = await axios.get(`${API_URL}/product/deleteProduct/${id}`, getToken())
  
  return res
}

const deleteProductPrice = async (id: any) => {
  // 
  const res = await axios.post(`${API_URL}/price/deleteProductPrice/${id}`, getToken())
  // 
  return res
}
const AddPrice = async (data: any) => {
  // 
  const res = await axios.post(`${API_URL}/addProductPrice`, {data}, getToken())
  return res
}
const updatePrice = async (id: any,price:any) => {
  const res = await axios.put(`${API_URL}/price/updatePrice/${id}/${price}`, getToken())
  return res
}

// get productlist-filter
const getFilterProduct = async()=>{
  const res = await axios.get(`${API_URL}/listproduct`, getToken())
  return res
}

// GET marketlist-filter
const getFilterMarket = async()=>{
  const res = await axios.get(`${API_URL}/listmarket`, getToken())
  return res
}

export {
  getCategory,
  getProduct,
  getMeasurements,
  createProduct,
  viewPrice,
  updateProduct,
  deleteProduct,
  deleteProductPrice,
  AddPrice,
  updatePrice,
  getFilterProduct,
  getFilterMarket,
}

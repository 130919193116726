import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {Field, useFormik} from 'formik'

import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../../auth'
import {AlertContext} from '../../../../../../_metronic/layout/components/alert-context'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useQueryResponse} from '../list/core/QueryResponseProvider'
import {createData, getPlan, viewData} from '../list/core/_requests'

//Model Interface
export interface IProfileDetails {
  user_id: any
  name?: string
  type?: string
  amount?: string
  days?: string
  advertisement?: string
  description?: string
  billno?: string
  status?: any
  plan_days: string
  plan_start: string
  plan_end: string
  plan_type: string
  Message?: string
  Code?: string
  employee_id?: string | undefined
}

//Model Initial Value
export let initialValues: IProfileDetails = {
  user_id: '',
  name: '',
  type: '',
  billno: '',
  amount: '',
  plan_days: '',
  plan_start: '',
  plan_end: '',
  advertisement: '',
  description: '',
  plan_type: '',
  status: '',
  Message: '',
  Code: '',
  employee_id: '',
}

//Validation
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name  is required'),
  amount: Yup.string().required('Amount is required'),
  plan_days: Yup.string().required('Plan Days is required'),
  plan_start: Yup.string().required('Plan Start is required'),
  plan_end: Yup.string().required('Plan End is required'),
  plan_type: Yup.string().required('Plantype is required'),
  // description: Yup.string().required('Give some short description'),
  // type: Yup.string().required('Select payment type'),
  // billno: Yup.string().required('Bill Number is required'),
})
//Function Starts
const RoleDetails: React.FC = () => {
  const today = new Date().toISOString().split('T')[0]
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)
  const [preview, setpreview] = useState<string | ArrayBuffer | null>(null)
  const [user, setuser] = useState<any>(null)
  const [plan, setplan] = useState<any>([])
  const {id} = useParams()
  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const type = currentUser?.type
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    viewData(id).then((res) => {
      formik.setFieldValue('name', res.data[0]?.name)
      formik.setFieldValue('user_id', res.data[0]?.id)
      formik.setFieldValue('mobile', res.data[0]?.mobile)
    })
    getPlan().then((res) => {
      setplan(res.Data)
    })
  }, [])
  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData ={
        userId :values.user_id,        
        planId :values.plan_type  ,
        description  :values.description
      }
      createData(updatedData).then((res) => {
        console.log("updatedData", res);
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/users/list/', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.code)
      })
    },
    enableReinitialize: true,
  })
  const handlePlan = (e: any) => {
    const value = e.target.value
    const selectedOption = e.target.options[value]
    const days = selectedOption.getAttribute('data-days')
    const amount = selectedOption.getAttribute('data-amt')
    formik.setFieldValue('plan_type', value)
    formik.setFieldValue('amount', amount)
    formik.setFieldValue('plan_days', days)
    const startDate = new Date() // Get the current date
    formik.setFieldValue('plan_start', startDate.toISOString().substr(0, 10))

    const daysToAdd = parseInt(selectedOption.getAttribute('data-days'), 10)
    if (!isNaN(daysToAdd)) {
      const endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + daysToAdd)

      formik.setFieldValue('plan_end', endDate.toISOString().substr(0, 10))
    }
  }
  //
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Upgrade Plan </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Name '
                    {...formik.getFieldProps('name')}
                    readOnly
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('plan_type')}
                    onChange={(e) => {
                      handlePlan(e)
                    }}
                  >
                    <option value=''>Select a Type</option>
                    {plan?.map((item: any, i: any) => {
                      return (
                        <option value={item?.id} data-amt={item?.amount} data-days={item?.days}>
                          {item?.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.plan_type && formik.errors.plan_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.plan_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Days</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('plan_days')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                    readOnly
                  />
                  {formik.touched.plan_days && formik.errors.plan_days && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.plan_days}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Amount</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Amount'
                    {...formik.getFieldProps('amount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                    readOnly
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan Start</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('plan_start')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                    min={today}
                  />
                  {formik.touched.plan_start && formik.errors.plan_start && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.plan_start}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Plan End</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('plan_end')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                    min={today}
                  />
                  {formik.touched.plan_end && formik.errors.plan_end && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.plan_end}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                {/* <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Type</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('type')}
                  >
                    <option value=''>Select a Type</option>

                    <option value='1'>Online </option>
                    <option value='2'>Offline</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.type}</div>
                    </div>
                  )}
                </div>
           
                  <div className='col-lg-6 fv-row'>
                    <label className='col-form-label required fw-bold fs-6'>Bill number</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Bill Number'
                    {...formik.getFieldProps('billno')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.billno && formik.errors.billno && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.billno}</div>
                    </div>
                  )}
                  </div> */}
         
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label  fw-bold fs-6'>Description</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Description'
                    {...formik.getFieldProps('description')}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Product} from '../../core/_models'
import Avatar from 'react-avatar'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {dateFormat} from '../../../../../auth/core/AuthHelpers'

type Props = {
  product: Product
  columnAccessor:string
}

const ProductInfoCell: FC<Props> = ({product,columnAccessor}) => {
 
  return (
    <>
    {columnAccessor === 'date' && (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        {/* <a href='#'>
         
        </a> */}
      </div>
      {dateFormat(product?.created_date)}
    </div>
    )}
    </>
  )
}

const ProductFilterCell :FC<Props> =({product})=>{
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      
    </div>
  )
}

const  MarketFilterCell :FC<Props> = ({product})=>{
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
     
    </div>
  )
}

export {
  ProductInfoCell,
  ProductFilterCell,
  MarketFilterCell
}

import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../../auth'
import {AlertContext} from '../../../../../../_metronic/layout/components/alert-context'
import {updateData} from '../list/core/_requests'
import {Plan} from '../list/core/_models'

const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name  is required'),
  amount: Yup.string().required('Amount is required'),
  user_type: Yup.string().required('Plantype is required'),
  days: Yup.string().required('Days is required'),
  description: Yup.string().required('Give some short description'),
})

type Props = {
  initialValues: Plan
}
export const EditEmployee: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<Plan>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.type
  //end get type
  const formik = useFormik<Plan>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        updatedData.employee_id = currentUser?.userId
        
        updateData(updatedData, id).then((res) => {
          showAlert(res?.Message, res?.Code)
          navigate('/plan', {replace: true})
        })
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Plan </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Name '
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Type</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('user_type')}
                  >
                    <option value=''>Select a TYpe</option>

                    <option value='1'>Free Plan</option>
                    <option value='0'>Payment Plan</option>
                  </select>
                  {formik.touched.user_type && formik.errors.user_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.user_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Days</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Validity Days'
                    {...formik.getFieldProps('days')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.days && formik.errors.days && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.days}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Amount</label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Amount'
                    {...formik.getFieldProps('amount')}
                    onWheel={(event) => {
                      var blurEve = event.target as HTMLInputElement
                      blurEve.blur()
                    }}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Description</label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Plan Description'
                    {...formik.getFieldProps('description')}
                  ></textarea>
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditEmployee

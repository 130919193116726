/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Product} from '../../core/_models'

type Props = {
  product: any
}

const dateFormat = (date: any) => {
  const parsedDate = new Date(date);
  
  // Check if the date is valid
  if (isNaN(parsedDate.getTime())) {
    return '-'; // Return a custom message for invalid dates
  }

  return parsedDate.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};


const ProductStartInfoCell: FC<Props> = ({product}) => {
  return <div className='symbol-label'>{dateFormat(product?.user_plan_detail?.startDate)}</div>
}
const ProductEndInfoCell: FC<Props> = ({product}) => {
  return <div className='symbol-label'>{dateFormat(product?.user_plan_detail?.endDate)}</div>
}
const ProductStatusCell: FC<Props> = ({ product }) => {
  const planDetail = product.user_plan_detail; // Destructure planDetail from the product object

  return planDetail?.isExpired ? (
    <span className="badge badge-danger">Expired</span>
  ) : (
    <span className="badge badge-success">Active</span>
  );
};
export {ProductStartInfoCell, ProductEndInfoCell , ProductStatusCell}

import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewMarket} from '../market-list/core/_requests'
import {EditEmployee} from '../market-form/EditEmployee'

export const EditMarketWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewMarket(id).then((res) => {
      
      setData(res?.data?.Data)
    })
  }, [])
  if (data) {
    return <EditEmployee initialValues={data} />
  }
  return null
}

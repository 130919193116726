import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Product, ProdcutQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

const getProduct = (query: string): Promise<ProdcutQueryResponse> => {
  return axios
    .get(`${API_URL}/bsproduct/query?${query}`, getToken())
    .then((response: AxiosResponse<ProdcutQueryResponse>) => {
      
      return response.data
    })
}
const getCategory = async () => {
  const res = await axios.get(`${API_URL}/bsproduct/getCategory`, getToken())
  
  return res
}
const createProduct = async (data: FormData) => {
  const res = await axios.post(`${API_URL}/bsproduct/add`, data, getToken())
  
  return res
}
const viewProduct = async (id: any) => {
  const res = await axios.get(`${API_URL}/bsproduct/view/${id}`, getToken())
  
  return res
}
const updateProduct = async (data: FormData, id: any) => {
  const res = await axios.post(`${API_URL}/bsproduct/edit/${id}`, data, getToken())
  
  return res
}
const deleteProduct = async (id: any) => {
  const res = await axios.get(`${API_URL}/bsproduct/delete/${id}`, getToken())
  
  return res
}

const enableCategory = async ( data:any,id: any) => {
  // 
  const res =   await axios.post(`${API_URL}/bsproduct/enablestatus/${id}`,data,getToken());
  return res
}

export {getCategory, getProduct, createProduct, viewProduct, updateProduct, deleteProduct,enableCategory}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {PaymentReceiveGraph} from './PaymentReceiveGraph'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {ListViewProvider} from '../core/ListViewProvider'
import {CardsWidget, CardsWidgetUser, ListsWidget, ListsWidgetRequest} from './ListsWidget'
import {MixedWidget} from './MixedWidget'
import { getBuyerProductsWithCount, getSellerProductsWithCount, getUserCountStateWise, PlanActiveUserCount, getUserTypeCount, getCurrentMonthCount } from './_requests'
import "./dashboard.css"
const DashboardContent: FC = () => {
  const [sellerProductCount, setSellerProductCount] = useState<any>([])
  const [buyerProductCount, setBuyerProductCount] = useState<any>([])
  const [userTypeCount, setUserTypeCount] = useState<any>([])
  const [stateWiseUserCount, setStateWiseUserCount] = useState<any>([])
  const [planActiveUserCount, setPlanActiveUserCount] = useState<any>([])
  const [allActiveUserCount, setAllActiveUserCount] = useState<any>('')
  const [totalActiveUsers, setTotalActiveUsers] = useState<any>('')
  const [userCountCurrentMonth, setUserCountCurrentMonth] = useState<any>({})

  useEffect(() => {
    getBuyerProductsWithCount().then((res:any)=>{
     setBuyerProductCount(res.data);
    })
    getSellerProductsWithCount().then((res:any)=>{
     setSellerProductCount(res.data);
    })
    getUserCountStateWise().then((res:any)=>{
     setStateWiseUserCount(res.data);
    })
    getUserTypeCount().then((res:any)=>{
     setUserTypeCount(res.data);
    })
    getCurrentMonthCount().then((res:any)=>{
      setUserCountCurrentMonth(res.data);
      setTotalActiveUsers(res.data.totalActiveUsers);
    })
    PlanActiveUserCount().then((res:any)=>{
      setAllActiveUserCount(res.totalActiveUserCount)
      setPlanActiveUserCount(res.data);
    })
  }, [])

  const intl = useIntl()


  

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
  
      <div className='row gy-5 gx-xxl-8 gx-md-5 gx-sm-3'>
        <div className='col-12'>
          <PaymentReceiveGraph className='card-xxl-stretch mb-5 mb-xl-8' totalActiveUsers={totalActiveUsers}/>
        </div>
      </div>
  
      <div className='row gy-5 gx-xxl-8 gx-md-5 gx-sm-3'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <MixedWidget userData={userTypeCount} />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <ListsWidget className='card-xl-stretch mb-xl-8' title="User Plan" subTitle={`${allActiveUserCount} Total Plan users`} tasks={planActiveUserCount} />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <ListsWidget className='card-xl-stretch mb-xl-8' title="Location States" subTitle="Latest State Statistics" tasks={stateWiseUserCount} />
        </div>
      </div>
  
      <div className='row gy-5 mt-5 gx-xxl-8 gx-md-5 gx-sm-3'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <ListsWidgetRequest className='card-xl-stretch mb-xl-8' title="Buyer Requirements" subTitle={`${buyerProductCount.length} Request`} tasks={buyerProductCount} />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <ListsWidgetRequest className='card-xl-stretch mb-xl-8' title="Seller Requirements" subTitle={`${sellerProductCount.length} Request`} tasks={sellerProductCount} />
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <CardsWidget
            className='h-md-50 mb-5 mb-xl-10'
            description='Request this month'
            requestCount={userCountCurrentMonth.requestCount}
            pendingCount={userCountCurrentMonth.pendingCount}
            color='#FFFF'
            img={toAbsoluteUrl('media/patterns/vector-1.png')}
          />
          <CardsWidgetUser
            className='h-md-50 mb-5 mb-xl-10'
            description='New Users this month'
            users={userCountCurrentMonth}
            labelColor='dark'
            textColor='gray-300'
          />
        </div>
      </div>
    </>
  )
  
  
}

export {DashboardContent}

const DashboardWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <DashboardContent />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {DashboardWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom'

import ReactHtmlParser from 'react-html-parser'

import {
  addmarketProduct,
  checkExisingProduct,
  getProductByCat,
  getProductMarket,
  removeProductMarket,
  viewMarket,
} from '../market-list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {getCategory} from '../../product/product-list/core/_requests'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {KTSVG} from '../../../../../_metronic/helpers'

export function View() {
  const {id} = useParams()
  const {showAlert} = useContext(AlertContext)
  const [mark, setMarket] = useState<any>([])
  const [category, setcategory] = useState<any>([])
  const [marProd, setmarProd] = useState<any>([])
  const [prod, setprod] = useState<any>([])
  const [showModal, setshowModal] = useState(false)
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [exProduct, setexProduct] = useState(false)
  const [catId, setcatId] = useState('')
  const [ClickedId, setClickedId] = useState('')
  const [prodId, setProdId] = useState('')
  const [categoryError, setcategoryError] = useState('')
  const [productError, setproductError] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    viewMarket(id).then((res: any) => {
      //
      setMarket(res.data?.Data)
    })
    getCategory().then((res: any) => {
      setcategory(res.data.data)
      //  
    })
  }, [])
  const getproductmarkets = () => {
    getProductMarket(id).then((res: any) => {
      setmarProd(res.data.Data)
      //  
    })
  }
  useEffect(() => {
    getproductmarkets()
  }, [])
  // 
  //remove client
  const handleEdit = () => {
    //  
    setshowModal(true)
    setProdId('')
    setcatId('')
    setcategoryError('')
    setproductError('')
    setexProduct(false)
  }

  const handleClose = () => {
    setshowModal(false)
  }
  const handleCategory = (e: any) => {
    setcatId(e.target.value)
    setProdId('')
    setcategoryError('')
    getProductByCat(e.target.value).then((res) => {
      //
      setprod(res.data?.data)
    })
    checkExistingProduct(prodId, e.target.value, id)
  }
  const checkExistingProduct = (prodId: any, catId: any, id: any) => {
    const datapost = {
      productId: prodId,
      categoryId: catId,
      marketId: id,
    }
    checkExisingProduct(datapost).then((res) => {
      //
      setexProduct(res.Data)
    })
  }
  const handleProduct = (e: any) => {
    setProdId(e.target.value)
    checkExistingProduct(e.target.value, catId, id)
    setproductError('')
  }
  const SubmitHandle = () => {
    const data = {
      productId: prodId,
      marketId: id,
      categoryId: catId,
    }
    if (prodId != '' && catId != '') {
      addmarketProduct(data).then((res) => {
        //
        setshowModal(false)
        // 
        // 
        showAlert(res?.data?.Message, res?.data?.Code)
        navigate('/market/view/' + id, {replace: true})
        getproductmarkets()
      })
    } else {
      if (prodId === '') {
        setproductError('Select any product')
      }
      if (catId === '') {
        setcategoryError('Select any category')
      }
    }
  }
  const deleteItem = () => {
    setshowDeleteModal(false)
    //
    removeProductMarket(ClickedId).then((res) => {
      setshowModal(false)

      showAlert(res?.data?.Message, res?.data?.Code)
      getproductmarkets()
    })
  }
  const handleDeleteClose = () => {
    setshowDeleteModal(false)
  }
  const handleDeleteShow = (id: any) => {
    setshowDeleteModal(true)
    setClickedId(id)
  }

   
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Market Details</h3>
          </div>
          <button onClick={handleEdit} className='btn btn-primary align-self-center'>
            Add Product
          </button>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Market Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {mark?.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Location</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{mark?.location} </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>City</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {mark?.marketCity?.name} </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>State</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {mark?.marketState?.name}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Country</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{mark?.marketCountry?.name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Product List</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <div className='fw-bold text-muted mb-4 pe-2'>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Category</th>
                        <th className='min-w-150px'>Product</th>
                        <th className='min-w-150px'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {marProd.length > 0 ? (
                        marProd.map((item: any, i: any) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a className='text-dark fw-bold text-hover-primary fs-6'>
                                      {item?.category?.name}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='card-toolbar'> {item?.product?.name}</div>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={() => handleDeleteShow(item?.id)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={1} style={{textAlign: 'center'}}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Market Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category</label>

            <div className='col-lg-8'>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Role'
                onChange={(e) => handleCategory(e)}
                value={catId}
              >
                <option value=''>Select a Category</option>
                {category.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              {categoryError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{categoryError}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Product</label>

            <div className='col-lg-8'>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Role'
                onChange={(e) => handleProduct(e)}
                value={prodId}
              >
                <option value=''>Select a Product</option>
                {prod.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              {productError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{productError}</div>
                </div>
              )}
            </div>
          </div>
          {exProduct ? (
            <p className='fw-bold text-danger text-center'>
              Product with these category already exist{' '}
            </p>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant='secondary' onClick={handleClose}>
              Not Now
            </Button>
            <Button variant='primary' disabled={exProduct} onClick={SubmitHandle}>
              Submit
            </Button>
          </>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete this product from market?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleDeleteClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

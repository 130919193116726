import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Category, CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
 

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios.get(`${API_URL}/news/categorylist/query?${query}`).then((d: AxiosResponse<CategoryQueryResponse>) => {
    
    return d.data
  })
}
 

const deleteCategoryById = (id: any) => {
  return axios
    .put(`${API_URL}/news/deleteNewsCategory/${id}`, getToken())

    .then((response: any) => response.data)
}

const createCategory = (data:any) => {
  return axios
    .post(`${API_URL}/news/addNewsCategory`, data, getToken())
    .then((response: any) => response.data)
}

const updateCategory = (name: any, id: any) => {
  return axios
    .put(`${API_URL}/news/updateNewsCategory/${id}`, {name}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}

 

 
 
export {
  getCategory,
  
  createCategory,
 
  deleteCategoryById,
  updateCategory,
  
}

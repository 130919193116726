import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useEffect, useState} from 'react'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import { EmployeeListFilter } from './EmployeeListFilter'
const EmployeeListToolbar = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const addPage = () => {
    navigate('/list-news/add')
  }
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 3).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      {/* {roleaccess?.add === 1 && (
        <button type='button' className='btn btn-primary' onClick={addPage}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add News
        </button>
      )} */}
      {/* end::Add user */}

      {/* filter for buyer */}
      <EmployeeListFilter />
    </div>
  )
}

export {EmployeeListToolbar}

import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EmployeeListHeader} from './components/header/EmployeeListHeader'
import {EmployeeTable} from './table/EmployeeTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'

const EmployeeList = () => {
  return (
    <>
      <KTCard>
        <EmployeeListHeader />
        <EmployeeTable />
      </KTCard>
    </>
  )
}
const EmployeeMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <EmployeeList />
      </div>
    </div>
  )
}
const EmployeeListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmployeeMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EmployeeListWrapper}

import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper, ViewListWrapper} from './popular-plan-list/EmployeeList'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Plan Management',
    path: '/popular-plan',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PopularPlanPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Popular plan list</PageTitle>
              <EmployeeListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default PopularPlanPage

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {listPlan, getscreenaccess, submitAccess} from '../list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

export function ViewAppSettings() {
  const {showAlert} = useContext(AlertContext)
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [loading, setloading] = useState<any>(false)
  const [btnDisable, setbtnDisable] = useState<any>(true)

  const [module, setModule] = useState<any>({})

  useEffect(() => {
    listPlan().then((res: any) => {
      setData(res.data?.Data)
      
    })
  }, [])
  console.log("data",data);
  
  const handlerole = (e: any) => {
    //
    if (e.target.value) {
      getscreenaccess(e.target.value).then((res) => {
        setModule(res.data.data)
        setbtnDisable(false)
      })
    } else {
      setModule({})
      setbtnDisable(true)
    }
  }
  
  const handleSubmit = () => {
    setloading(true)
    submitAccess(module).then((res: any) => {
      setloading(false)
      showAlert(res?.data?.Message, res?.data?.Code)
    })
  }
  
  const handleCheckboxChange = (option: any) => {
    setModule((prevState: any) => ({
      ...prevState,
      [option]: prevState[option] === 1 ? 0 : 1,
    }))
  }

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Details</h3>
              </div>
            </div>

            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='row mb-10'>
                <div className='col-lg-2'>
                  <label className=' col-form-label  fw-bold fs-6'>Select Plan Type</label>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    onChange={handlerole}
                    id='roleDropdown'
                  >
                    <option value=''>All </option>
                    {data?.map((item: any) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-50px'> NO</th>
                      <th className='min-w-340px'> NAME</th>
                      <th className='min-w-100px'>Access</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Buy</td>
                      <td>
                        <input
                          type='checkbox'
                          checked={module?.buy === 1 ? true : false}
                          onChange={() => handleCheckboxChange('buy')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Sell</td>
                      <td>
                        <input
                          type='checkbox'
                          checked={module?.sell === 1 ? true : false}
                          onChange={() => handleCheckboxChange('sell')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Market</td>
                      <td>
                        <input
                          type='checkbox'
                          checked={module?.market === 1 ? true : false}
                          onChange={() => handleCheckboxChange('market')}
                        />
                      </td>
                    </tr>
                  </tbody>
                  {/* end::Table body */}
                </table>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    type='submit'
                    onClick={handleSubmit}
                    className='btn btn-primary'
                    disabled={btnDisable}
                  >
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import {useListView} from '../../core/ListViewProvider'
import {CategoryListSearchComponent} from './CategoryListSearchComponent'
import { CategoryListToolbar } from './CategoryListToolbar'

const CategoryListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <CategoryListSearchComponent />
       {/* begin::Card toolbar */}
       <div className='card-toolbar'>
        {/* begin::Group actions */}
         {/* <CategoryListToolbar />  */}
        {/* end::Group actions */}
      </div>
    </div>
  )
}

export {CategoryListHeader}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Plan } from '../../core/_models'
import Avatar from 'react-avatar'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'

type Props = {
  Plan: Plan
}

const EmployeeInfoCell: FC<Props> = ({ Plan }) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      {Plan?.payment_type === 1 ? 'Free Plan' : 'Payment Plan'}
    </div>
  )
}

export { EmployeeInfoCell }

import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateUnit} from '../unit-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {MyContext} from '../unit-list/ContextFile'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../unit-list/core/QueryResponseProvider'
import {useAuth} from '../../../auth'

export interface IProfileDetails {
  name: string
}

export let initialValues: IProfileDetails = {
  name: '',
}
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Unit Name should only contain alphabetic characters')
    .required('Unit is required'),
})

const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const {state, setState} = useContext(MyContext)
  let initialValues = state

  const [data, setData] = useState(state)
  const setValues = () => {
    formik.setFieldValue('name', '')
    formik.setFieldValue('description', '')
  }
  useEffect(() => {
    setData(state)
    formik.setFieldValue('name', state?.name)
    formik.setFieldValue('description', state?.description)
    formik.setFieldValue('primaryId', state?.primaryId)
  }, [state])
  

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      

      updateUnit(updatedData?.name, currentUser?.userId, state.primaryId).then((res) => {
        
        setValues()
        setState({
          ...state,
          primaryId: ``,
          name: ``,
          editclicked: false,
        })
        navigate('/unit/list', {replace: true})
        setLoading(false)
        //  showAlert(res?.Message, res?.Code)
        refetch()
      })
    },
    enableReinitialize: true,
  })

  const handleCancel = () => {
    navigate(-1)
  }

  // 
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Unit </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Unit Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Unit Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/** Description Info */}
            {/* <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Category Description
                  </label>
                  <textarea
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Category Description'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='button' className='btn btn-secondary ms-2' onClick={handleCancel}>
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Update Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails

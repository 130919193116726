import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Unit, CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS

const getUnit = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${API_URL}/unit/query?${query}`, getToken())
    .then((d: AxiosResponse<CategoryQueryResponse>) => {
      
      return d.data
    })
}

const createUnit = (data: any) => {
  return axios.post(`${API_URL}/unit/add`, data, getToken()).then((response: any) => response.data)
}

const updateUnit = (name: any, updatedBy: any, id: any) => {
  return axios
    .put(`${API_URL}/unit/update/${id}`, {name, updatedBy}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}
const deleteUnit = (id: any) => {
  return axios.get(`${API_URL}/unit/delete/${id}`, getToken()).then((response: any) => {
    
    return response.data
  })
}

export {getUnit, createUnit, updateUnit, deleteUnit}

import {CategoryListHeader} from './components/header/CategoryListHeader'
import {CategoryTable} from './table/CategoryTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddCategory, EditCategory} from '../news-category-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {ContextProvider} from './ContextProvider'

const CategoryAdd = () => {
  const {state, setState} = useContext(MyContext)
  
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>{state.editclicked === true ? <EditCategory /> : <AddCategory />}</KTCard>
    </>
  )
}

const CategoryList = () => {
  return (
    <>
      <KTCard>
        <CategoryListHeader />
        <CategoryTable />
      </KTCard>
    </>
  )
}
const MainPage = () => {
  const {show, message, type} = useContext(AlertContext)
  

  return (
    <>
      {show && <AlertBox type={type == '1' ? 'success' : 'error'} message={message} />}
      <div className='row'>
        <div className='col-lg-5'>
          <CategoryAdd />
        </div>
        <div className='col-lg-7'>
          <CategoryList />
        </div>
      </div>
    </>
  )
}

const CategoryListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <MainPage />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CategoryListWrapper}

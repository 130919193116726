/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'

import Avatar from 'react-avatar'
import {dateFormat, useAuth} from '../../../auth'
import {viewData} from '../user-list/core/_requests'

export function ViewUser() {
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  useEffect(() => {
    viewData(id).then((res: any) => {
      console.log("rrr",res.data[0]);
      
      setData(res.data[0])
    })
  }, [])
// Helper function to format dates
const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString()
}
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>User Details</h3>
              </div>
            </div>

            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>User ID</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.id}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>User Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.name}</span>
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.mobile}</span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type Props = {
  userData : any
};

const MixedWidget: React.FC<Props> = ({ userData }) => {


  return (
    <div className={`card text-white`}>
      {/* Header */}
      <div className='card-header bg-success h-150px border-0 pt-5'>
        <h3 className='flex-column mt-5 pt-5'>
          <span className='card-label fw-bold text-light'>User Type</span>
          <span className='card-title mt-2 fw-semibold fs-7 text-light'>
            You have {userData.length} User Types
          </span>
        </h3>
      </div>

      {/* Body */}
      <div className='card-body p-0'>
        {/* Stats */}
        <div className='card-p mt-n20 position-relative'>
          <div className='row g-3'>
            {userData.map((user :any, index:any) => (
              <div className='col-6' key={index}>
                <div className='bg-light-success rounded-2 d-flex flex-column align-items-center p-3 h-100'>
                  <img
                    src={user.icon}
                    alt={user.name}
                    className='img-fluid mb-2'
                    style={{ width: '30px', height: '30px' }}
                  />
                  <span className='fw-bold text-dark fs-5'>{user.userCount}</span>
                  <span className='fs-6 text-dark'>{user.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { MixedWidget };

// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Amount' className='min-w-125px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Days' className='min-w-125px' />
    ),
    accessor: 'days',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='User Type' className='min-w-125px' />
    ),
    accessor: 'planType.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='type' className='min-w-125px' />
    ),
    accessor: 'user_type',
    Cell: ({...props}) => <EmployeeInfoCell Plan={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell employees={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}

import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Logout, AuthPage, useAuth} from '../modules/auth'

import {getRolewiseRoutes} from './_requests'
import {AccessDeniedWrapper} from '../modules/apps/access-denied/access-denied--list/AccessDeniedWrapper'
import {routeRedirectSidemenu} from '../modules/apps/role-access/role-setting-list/core/_requests'
import AccountPage from '../modules/accounts/AccountPage'
import PasswordPage from '../modules/accounts/PasswordPage'
import CategoryMasterPage from '../modules/apps/category-master/CategoryMasterPage'
import UnitPage from '../modules/apps/unit/UnitPage'
import MarketPage from '../modules/apps/market/MarketPage'
import ProductPage from '../modules/apps/product/ProductPage'
import ProductPricePage from '../modules/apps/productprice/ProductPricePage'
import NewsCategoryPage from '../modules/apps/news-category/NewsCategoryPage'
import NewsPage from '../modules/apps/news/NewsPage'
import PlanPage from '../modules/apps/plan/PlanPage'
import BsCategoryPage from '../modules/apps/bs-category/BsCategoryPage'
import BsProductPage from '../modules/apps/bs-product/BsProductPage'
import BuyPage from '../modules/apps/buy/BuyPage'
import SellPage from '../modules/apps/sell/SellPage'
import UserPage from '../modules/apps/users/UserPage'
import SubscriptionsPage from '../modules/apps/subscription/SubscriptionsPage'
import FeedbckPage from '../modules/apps/feedback/FeedbckPage'
import SettingsPage from '../modules/apps/settings/SettingsPage'
import PlanCategoryPage from '../modules/apps/plan-category/PlanCategoryPage'
import PopularPlanPage from '../modules/apps/popular-plan/PopularPlanPage'
import ReportPage from '../modules/apps/report/ReportPage'
import UserTypePage from '../modules/apps/user-type/UserTypePage'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const [appRouteAccess, setappRouteAccess] = useState<any>([])
  const [routePermission, setroutePermission] = useState<any>([])
  const currentRole = currentUser?.roleId
  useEffect(() => {
    routeRedirectSidemenu(currentRole).then((res: any) => {
      //
      const sidemenuitems = res.data.Data
      const modules = sidemenuitems.map((response: {module: any}) => response.module.link)
      setroutePermission(modules)
    })
  }, [])
  useEffect(() => {
    getRolewiseRoutes(currentUser?.roleId).then((res: any) => {
      var crudres = res.data?.Data[0]
      setappRouteAccess(crudres)
    })
  }, [])
  
  const RolePage = lazy(() => import('../modules/apps/role/RolePage'))

  const EmployeePage = lazy(() => import('../modules/apps/employee/EmployeePage'))
  const CategoryPage = lazy(() => import('../modules/apps/category/CategoryPage'))
  const RoleaccessPage = lazy(() => import('../modules/apps/role-access/RoleaccessPage'))
  const ProfilePage = lazy(() => import('../modules/apps/profile/ProfilePage'))
  const AccessDeniedPage = lazy(() => import('../modules/apps/access-denied/AccessDeniedPage'))
  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />

          <Route
            path='role/*'
            element={
              <SuspendedView>
                {routePermission.includes('/role') ? <RolePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='employee/*'
            element={
              <SuspendedView>
                {routePermission.includes('/employee') ? <EmployeePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='product-category/*'
            element={
              <SuspendedView>
                {routePermission.includes('/product-category') ? (
                  <CategoryPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='categoryMaster/*'
            element={
              <SuspendedView>
                {routePermission.includes('/product-category') ? (
                  <CategoryMasterPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='unit/*'
            element={
              <SuspendedView>
                {routePermission.includes('/unit') ? <UnitPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='market/*'
            element={
              <SuspendedView>
                {routePermission.includes('/market') ? <MarketPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='products/*'
            element={
              <SuspendedView>
                {routePermission.includes('/products') ? <ProductPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='price/*'
            element={
              <SuspendedView>
                {routePermission.includes('/price') ? <ProductPricePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='newsCategory/*'
            element={
              <SuspendedView>
                {routePermission.includes('/newsCategory') ? (
                  <NewsCategoryPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='list-news/*'
            element={
              <SuspendedView>
                {routePermission.includes('/list-news') ? <NewsPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='plan/*'
            element={
              <SuspendedView>
                {routePermission.includes('/list-news') ? <PlanPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='bs-category/*'
            element={
              <SuspendedView>
                {routePermission.includes('/bs-category') ? (
                  <BsCategoryPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='bsProduct/*'
            element={
              <SuspendedView>
                {routePermission.includes('/bsProduct') ? <BsProductPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='users/*'
            element={
              <SuspendedView>
                {routePermission.includes('/users') ? <UserPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='subscription/*'
            element={
              <SuspendedView>
                {routePermission.includes('/subscription') ? (
                  <SubscriptionsPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='buyer-request/*'
            element={
              <SuspendedView>
                {routePermission.includes('/buyer-request') ? <BuyPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='seller-request/*'
            element={
              <SuspendedView>
                {routePermission.includes('/seller-request') ? <SellPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='feedback/*'
            element={
              <SuspendedView>
                {routePermission.includes('/feedback') ? <FeedbckPage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                {routePermission.includes('/page-access') ? (
                  <RoleaccessPage />
                ) : (
                  <AccessDeniedPage />
                )}
              </SuspendedView>
            }
          />
          <Route
            path='user-profile/*'
            element={
              <SuspendedView>
                {routePermission.includes('/user-profile') ? <ProfilePage /> : <AccessDeniedPage />}
              </SuspendedView>
            }
          />
          <Route
            path='profile/*'
            element={
              <SuspendedView>
                <AccountPage />
              </SuspendedView>
            }
          />
          <Route
            path='settings/*'
            element={
              <SuspendedView>
                <SettingsPage />
              </SuspendedView>
            }
          />
          <Route
            path='changePassword/*'
            element={
              <SuspendedView>
                <PasswordPage />
              </SuspendedView>
            }
          />
          <Route
            path='userplantype/*'
            element={
              <SuspendedView>
                <PlanCategoryPage />
              </SuspendedView>
            }
          />
          <Route
            path='popular-plan/*'
            element={
              <SuspendedView>
                <PopularPlanPage />
              </SuspendedView>
            }
          />
           <Route
            path='report/*'
            element={
              <SuspendedView>
                <ReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='user-type/*'
            element={
              <SuspendedView>
                <UserTypePage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'

import {getToken} from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URLS
const ROLE_URL = `${API_URL}`
var AuthVerify = getToken.call(this)

const getRole = async () => {
  const res = await axios.get(`${ROLE_URL}/getRole`, getToken())
  return res
}
const getmodules = async () => {
  const res = await axios.get(`${ROLE_URL}/getmodule`, getToken())
  return res
}
const createroleaccess = async (data: any) => {
  const res = await axios.post(`${ROLE_URL}/addNewRoleAccess`, {data}, getToken())
  return res
}
const getroleaccess = async (roleId: any) => {
  const res = await axios.put(`${ROLE_URL}/getExistingPermissions/${roleId}`, getToken())
  return res
}
const getrolewisesidemenu = async (roleId: any) => {
  
  const res = await axios.get(`${ROLE_URL}/getModulesForSidebar/${roleId}`, getToken())
  return res
}
const routeRedirectSidemenu = async (roleId: any) => {
  const res = await axios.get(`${ROLE_URL}/getRouteLinkList/${roleId}`, getToken())
  return res
}
const widgetCount = async (roletype: any, roletypeId: any, type: any, typeId: any) => {
  const res = await axios.get(
    `${ROLE_URL}/widgetCount/${roletype}/${roletypeId}/${type}/${typeId}`,
    getToken()
  )
  return res
}
const getExCRUD = async (roleId: any, moduleId: any) => {
  const res = await axios.post(`${ROLE_URL}/getCRUDExists`, {roleId, moduleId}, getToken())
  return res
}
export {
  getRole,
  getmodules,
  createroleaccess,
  getroleaccess,
  getrolewisesidemenu,
  widgetCount,
  routeRedirectSidemenu,
  getExCRUD,
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteById} from '../../core/_requests'
import {News} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  buy: any
}

const EmployeeActionsCell: FC<Props> = ({buy}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const editDetail = () => {
    navigate('/buyer-request/view/' + buy.primaryId)
  }
  const deleteItem = () =>
    deleteById(buy.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.Message, res?.Code)
      navigate('/list-news')
    })
  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const {currentUser} = useAuth()
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 3).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  return (
    <>
      {/* Edit Button */}
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {roleaccess?.view === 1 && (
          <a
            onClick={editDetail}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/general/eye.svg' className='svg-icon-3' />
          </a>
        )}

        {/* Delete Button */}
        {/* <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a> */}
      </div>

      {/* Modal */}
      {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{buy?.title}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Modal */}
    </>
  )
}

export {EmployeeActionsCell}

// @ts-nocheck
import {Column} from 'react-table'
import {ProductStartInfoCell, ProductEndInfoCell} from './ProductInfoCell'

import {ProductCustomHeader} from './ProductCustomHeader'
import {Product} from '../../core/_models'
import { dateFormat } from '../../../../../auth'

const EmployeeColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='User Name' className='min-w-125px' />
    ),
    accessor: 'user.name',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'user.mobile',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'feedback_category.name',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Message' className='min-w-200px' />
    ),
    accessor: 'message',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Created On' className='min-w-125px' />
    ),
    accessor: 'created',
    Cell: ({ row }) => (
      <div>{dateFormat(row.original.created)}</div>
    ),
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Rating' className='min-w-125px' />
    ),
    accessor: 'rating',
  },
]

export {EmployeeColumns}

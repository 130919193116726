import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {News, NewsQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

// LIST
const getData = (query: string): Promise<NewsQueryResponse> => {
  return axios
    .get(`${API_URL}/listSellProduct/query?${query}`, getToken())
    .then((response: AxiosResponse<NewsQueryResponse>) => {
      
      return response.data
    })
}
 
// VEIW
const viewData= (id: any) => {
  return axios.get(`${API_URL}/SellProduct/view/${id}`, getToken()).then((response) => {
    return response.data
  })
} 

// get product
const getListBuySellProduct = async()=>{
  const res = await axios.get(`${API_URL}/listBuySellProduct`, getToken())
  return res
}

// get location
const getSellLocationlist = async()=>{
  const res = await axios.get(`${API_URL}/listSellLocation`,getToken())
  return res
}

export {
  getData, 
  viewData,
  getSellLocationlist,
  getListBuySellProduct
 }

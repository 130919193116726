import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useContext, useEffect, useState} from 'react'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'
import {Button, Modal} from 'react-bootstrap'
import {getPlan, updatePopularPlan} from '../../core/_requests'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'

const EmployeeListToolbar = () => {
  const {currentUser} = useAuth()
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [plan, setPlan] = useState<any>([])
  const [showModal, setshowModal] = useState(false)
  const [prodId, setProdId] = useState('')
  const [productError, setproductError] = useState('')
  const addMarketPage = () => {
    //  
    setshowModal(true)
    setProdId('')
    setproductError('')
  }
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 3).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  useEffect(() => {
    getplandetails()
  }, [])
  const getplandetails = () => {
    getPlan().then((res: any) => {
      // 
      setPlan(res.data.Data)
    })
  }
  const handleClose = () => {
    setshowModal(false)
  }
  const handleProduct = (e: any) => {
    // 
    setProdId(e.target.value)
    //checkExistingProduct(e.target.value, catId, id)
    setproductError('')
  }
  const SubmitHandle = () => {
    if (prodId != '') {
      updatePopularPlan(prodId, 1).then((res) => {
        // 
        setshowModal(false)
        // 
        // 
        showAlert(res?.data?.Message, res?.data?.Code)
        navigate('/popular-plan/')
        getplandetails()
      })
    } else {
      if (prodId === '') {
        setproductError('Select any plan')
      }
    }
  }
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Add user */}
        {roleaccess?.add === 1 && (
          <button type='button' className='btn btn-primary' onClick={addMarketPage}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Popular Plan
          </button>
        )}
        {/* end::Add user */}
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row col-lg-12 mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Plan</label>

            <div className='col-lg-8'>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Role'
                onChange={(e) => handleProduct(e)}
                value={prodId}
              >
                <option value=''>Select a Plan</option>
                {plan.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              {productError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{productError}</div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant='secondary' onClick={handleClose}>
              Not Now
            </Button>
            <Button variant='primary' onClick={SubmitHandle}>
              Submit
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {EmployeeListToolbar}

import React, { useState, useEffect } from 'react';
import { enableCategory } from '../../core/_requests';
import {Market} from '../../core/_models';

type Props = {
 employees: Market;
  columnAccessor: string;
};

const MarketInfoCell: React.FC<Props> = ({ employees, columnAccessor }) => {

  const [enableValue, setEnableValue] = useState<number>(parseInt(employees.enable_status, 10));
 
  const handleStatus = () => {
    const updatedStatus = enableValue === 1 ? 0 : 1; 
    setEnableValue(updatedStatus); 
    const data = {
      enable_status: updatedStatus.toString(),  
    };
    const id = employees.primaryId;
    
    enableCategory(data, id)
      .then((res: any) => {
        
      })
      .catch((error: any) => {
        console.error('Error updating category status:', error);
      });
  };


  return (
    <>
      {columnAccessor === 'image' && (
        <div className='d-flex align-items-center'>
          {/* begin:: Avatar */}
          <div className='d-flex flex-column'>
            {/* <img src={cat?.image} style={{ width: '100px', height: '100px' }} alt='Category' /> */}
          </div>
        </div>
      )}

      {columnAccessor === 'status' && (
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            defaultValue='1'
            checked={enableValue === 1}
            onChange={handleStatus}
          />
          <label className='form-check-label' htmlFor='enableSwitch'>
            {/* {enableValue === 1 ? 'Enabled' : 'Disabled'} */}
          </label>
        </div>
      )}
    </>
  );
};

export { MarketInfoCell };

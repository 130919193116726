/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import ReactHtmlParser from 'react-html-parser'

import {viewNews} from '../news-list/core/_requests'
export function View() {
  const {id} = useParams()

  const [news, setNews] = useState<any>([])

  useEffect(() => {
    viewNews(id).then((res: any) => {
      setNews(res.Data)
    })
  }, [])
  var editsubsob = '/list-news/edit/' + id

  //remove client

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>News Details</h3>
          </div>
          <Link to={editsubsob} className='btn btn-primary align-self-center'>
            Edit News
          </Link>
        </div>

        <div className='card-body p-9'>
          {news?.short_image_name ? (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Image</label>

              <div className='col-lg-8'>
                <img src={news?.short_image_name} height='100' />
              </div>
            </div>
          ) : (
            ''
          )}

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Title</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {news?.title}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{news?.news_category?.name} </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Location</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {news?.location} </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Short Message</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'> {news?.short_message}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Long Message</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {' '}
                {ReactHtmlParser(news?.long_message)}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

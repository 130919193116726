/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'

import Avatar from 'react-avatar'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

export function UserHeader() {
  const location = useLocation()
  const {show, message, type} = useContext(AlertContext)
  var razarpay = '/settings/razarpay'
  var smsGateway = '/settings/smsGateway'
  var buySellValidity = '/settings/buySellValidity'
  var appSettings = '/settings/appSettings'
  var appVersion = '/settings/appVersion'

  return (
    <>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <Avatar
                  name='Coconut Market App'
                  maxInitials={2}
                  round
                  color='#607458'
                  fgColor='#1b1b29'
                ></Avatar>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Coconut Market App
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === razarpay && 'active')
                  }
                  to={razarpay}
                >
                  Razorpay Settings
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === smsGateway && 'active')
                  }
                  to={smsGateway}
                >
                  SMS Gateway Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === buySellValidity && 'active')
                  }
                  to={buySellValidity}
                >
                  Buy & Sell Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === appSettings && 'active')
                  }
                  to={appSettings}
                >
                  App Access Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === appVersion && 'active')
                  }
                  to={appVersion}
                >
                  App Version
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

// @ts-nocheck
import {Column} from 'react-table'

import {CategoryActionsCell} from './CategoryActionsCell'
import {CategoryCustomHeader} from './CategoryCustomHeader'
import {Category} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Category>> = [
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CategoryActionsCell id={props.data[props.row.index].id} cate={props.data[props.row.index]} />
    ),
  },
]

export {usersColumns}
